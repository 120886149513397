<div class="itech-common-wrap" id="interceptNoAccess">
  <div style="width: 100%;display:flex;overflow:visible">
    <clr-signpost class="itech-sync-status-lbl" *ngIf="syncState" style="width:60px;height:50px;z-index:1">
      <a class="itech-inline-link-btn" style="color:green;min-width:100%;min-height:100%;" clrSignpostTrigger>
        <ng-container *ngIf="equalsSome(syncState.mercuryExchangeStatus, 2, 3, 4); else notInProgressSyncState">
          <span class="spinner spinner-sm" style="border-radius:50%;margin-top:10px;"></span>
        </ng-container>
          <ng-template #notInProgressSyncState>
            <clr-icon shape="success-standard" size="24" style="border-radius:50%;margin-top:10px;"></clr-icon>
          </ng-template>
      </a>
      <clr-signpost-content [clrPosition]="'right-bottom'" class="compact form itech-highlighted-sections"
                            style="max-width:23rem" *clrIfOpen>
        <div class="compact form itech-highlighted-sections">
          <section class="form-block itech-block-medium">
            <div class="form-group">
              <label>Дата посл. операции</label>
              <div class="itech-text-field itech-control-large">
                {{syncState.mercuryExchangeLastTime|rtime}}
              </div>
            </div>
            <div class="form-group" *ngIf="syncState.lastAppliedTimeFrom && syncState.lastAppliedTimeTo">
              <label>Период загрузки</label>
              <ng-container *ngIf="!syncState.isEditStarted; else changeLastAppliedTimeTo">
                <div class="itech-text-field itech-control-large">
                  {{syncState.lastAppliedTimeFrom|rtime}} - {{syncState.lastAppliedTimeTo|rtime}}
                </div>
              </ng-container>
              <ng-template #changeLastAppliedTimeTo>
                <div class="itech-control-normal">
                  {{syncState.lastAppliedTimeFrom|rtime}} -
                  <app-datetime-picker [contextControlName]="'lastAppliedTimeTo'" [contextFormGroup]="syncStateForUpdate"
                                       id="lastAppliedTimeTo" [withTime]="false"></app-datetime-picker>
                </div>
              </ng-template>
            </div>
            <ng-container *ngIf="syncStateResult; else noParseResult">
              <div class="form-group" [style.color]="+syncStateResult.all > 0 ? 'green' : undefined">
                <label>Всего обработано</label>
                <div class="itech-text-field itech-control-large">
                  {{syncStateResult.all}}
                </div>
              </div>
              <div class="form-group" [style.color]="+syncStateResult.new > 0 ? 'green' : undefined">
                <label>Добавлено новых</label>
                <div class="itech-text-field itech-control-large">
                  {{syncStateResult.new}}
                </div>
              </div>
              <div class="form-group" [style.color]="+syncStateResult.update > 0 ? 'green' : undefined">
                <label>Обновлено существующих</label>
                <div class="itech-text-field itech-control-large">
                  {{syncStateResult.update}}
                </div>
              </div>
              <div class="form-group">
                <label>Пропущено (существует)</label>
                <div class="itech-text-field itech-control-large">
                  {{syncStateResult.skippedExisting}}
                </div>
              </div>
              <div class="form-group">
                <label>Пропущено (истёк срок годности)</label>
                <div class="itech-text-field itech-control-large">
                  {{syncStateResult.skippedExpirationDate}}
                </div>
              </div>
              <div class="form-group">
                <label>Пропущено (не поддерживается тип ВСД)</label>
                <div class="itech-text-field itech-control-large">
                  {{syncStateResult.skippedTypeNotSupport}}
                </div>
              </div>
              <div class="form-group" [style.color]="+syncStateResult.error > 0 ? 'red' : undefined">
                <label>Возникли ошибки</label>
                <div class="itech-text-field itech-control-large">
                  {{syncStateResult.error}}
                </div>
              </div>
              <div class="form-group" *ngIf="syncStateResult.filename">
                <label></label>
                <a class="itech-link in-table-link itech-control-large" (click)="downloadLog(syncStateResult.filename)">Скачать лог</a>
              </div>
            </ng-container>
            <ng-template #noParseResult>
              <div class="form-group">
                <label></label>
                <div [style.color]="syncState.mercuryExchangeLastResult < 0 ? 'red' : undefined"
                     style="word-break: break-word;"
                     class="itech-text-field itech-control-large">
                  {{syncState.mercuryExchangeLastResultText}}
                </div>
              </div>
            </ng-template>
            <div *ngIf="(securityService.getUserInfo() | async).allowedEditMercuryConversation">
              <ng-container *ngIf="syncState.isEditStarted; else editButton">
                <button type="button" class="btn btn-link itech-inline-link-btn" style="padding-right: 80px" (click)="saveEditSyncState(syncState)">
                  <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
                </button>
                <button type="button" class="btn btn-link itech-inline-link-btn" (click)="cancelEditSyncState(syncState)">
                  <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
                </button>
              </ng-container>
              <ng-template #editButton>
                <button type="button" class="signpost-edit-button" title="Редактировать" (click)="startEditSyncState(syncState)">
                  <clr-icon shape="edit"></clr-icon>
                </button>
              </ng-template>
            </div>
          </section>
        </div>
      </clr-signpost-content>
    </clr-signpost>
    <app-products-search-results-with-tabs [data]="model.searchResults" [templates]="model.templates"
                                           [columnDefs]="gridColumnDefs" [uniqueGridName]="'EditStableProductsGrid'"
                                           [frameworkComponents]="frameworkComponents" [detailCellRenderer]="detailCellRenderer"
                                           [gridIcons]="gridIcons" [getRowStyle]="getRowStyle" [currentStableId]="id"
                                           [exportFileName]="'Продукция у ПО ' + (id|lookup:'stable'|async)"
                                           (rowDoubleClicked)="navigationService.performProductEditing($event.data.id)"
                                           (selectionChanged)="onGridSelectionChanged($event)"
                                           (modelUpdated)="updateSelected($event)">
    </app-products-search-results-with-tabs>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>

      <clr-dropdown>
        <button class="btn btn-secondary" clrDropdownToggle>
          Действия
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen [clrPosition]="'top-left'">
          <app-btn-sync-web [caption]="'Обновить из Меркурия'" [iconName]="'refresh'" clrDropdownItem
                            (action)="updateFromMercuryAndRefreshFormData()"
                            [notSessionCaption]="'загрузка заявок требует авторизации'" [disabledIfNotSession]="false">
          </app-btn-sync-web>
          <div class="dropdown-divider"></div>
          <button type="button" clrDropdownItem (click)="sendToRegistration()"
                  [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">Отправить на регистрацию</button>
          <button type="button" clrDropdownItem (click)="sendToClearance()"
                  [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">Погасить документы</button>
          <div class="dropdown-divider"></div>
          <app-btn-sync-web [caption]="'Принять заявки от ХС'" clrDropdownItem
                            [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true"
                            (action)="sendToAcceptManyProductRequests()">
          </app-btn-sync-web>
          <div class="dropdown-divider"></div>
          <button type="button" clrDropdownItem (click)="createTransportForSelectedDocuments()"
                  [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">Оформить транспортный ВСД</button>
          <div class="dropdown-divider"></div>
          <app-products-reports-button [model]="model">
          </app-products-reports-button>
          <div class="dropdown-divider"></div>
          <button type="button" clrDropdownItem (click)="deleteDocument()"
                  [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">Удалить документ(ы)</button>
        </clr-dropdown-menu>
      </clr-dropdown>

      <button class="btn btn-success-outline" (click)="addNew()">
        <clr-icon shape="plus"></clr-icon>&nbsp;Добавить новый
      </button>
    </div>

    <div style="width: 210px; display: flex; align-items: center;">
      <button (click)="getPreviousMonth()" style="border: none; outline: none;">
        <clr-icon shape="rewind"></clr-icon>&nbsp;
      </button>&nbsp;

      <clr-dropdown style="margin: 0 0.5rem 0 0.5rem">
        <button class="btn btn-warning-outline" clrDropdownToggle>
          {{(model.currentDate.getMonth()+1|lookup:'months'|async)}}, {{model.currentDate.getFullYear()%100}}
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen [clrPosition]="'top-left'">
          <label class="dropdown-header">Выберите дату</label>

          <button class="btn btn-primary-outline"
                  *ngFor="let date of productDates"  type="button" clrDropdownItem (click)="changeDate(date)">{{(date.getMonth()+1|lookup:'months'|async)}},{{date.getFullYear()%100}}
          </button>

        </clr-dropdown-menu>
      </clr-dropdown>

        <button (click)="getNextMonth()" style="border: none; outline: none; position: absolute; right: 0">
        <clr-icon shape="fast-forward"></clr-icon>&nbsp;
      </button>
    </div>
  </div>
</div>
