import { EventEmitter, OnChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { DateHelper } from '../../../helpers/date-helper';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';
import { StableSearchModalComponent } from '../../edit-stable/search/stable-search-modal.component';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { combineLatest } from 'rxjs';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { EditAnimalEventCommonFormComponent } from '../events/edit-animal-event-common-form.component';
var EditAnimalCommonFormComponent = /** @class */ (function () {
    function EditAnimalCommonFormComponent(fb, animalDataService, navigationService, cacheService, appNavigationService, lookupService) {
        this.fb = fb;
        this.animalDataService = animalDataService;
        this.navigationService = navigationService;
        this.cacheService = cacheService;
        this.appNavigationService = appNavigationService;
        this.lookupService = lookupService;
        this.onNavigateEventsClicked = new EventEmitter();
        this.isInvalid = FormHelper.isInvalid;
        this.placeholderPinTemplate = undefined;
        this.processMoneyKeypress = FormHelper.processMoneyKeypressDot;
    }
    Object.defineProperty(EditAnimalCommonFormComponent.prototype, "isExternalAnimal", {
        get: function () {
            return !this.contextFormGroup.get('id').value && !!this.contextFormGroup.get('tempId').value;
        },
        enumerable: true,
        configurable: true
    });
    EditAnimalCommonFormComponent.createFormGroup = function (fb, serverSideErrorsProvider, val, isClone) {
        if (isClone === void 0) { isClone = false; }
        var group = fb.group({
            __stub__history: false,
            __stub__stable_history: false,
            id: val.id,
            tempId: val.tempId,
            createdTime: val.createdTime,
            createdUser: val.createdUser,
            deletedTime: val.deletedTime,
            deletedUser: val.deletedUser,
            idNumber: val.idNumber,
            animalGroup: val.animalGroup,
            animalTypeId: [val.animalTypeId, Validators.compose([Validators.required,
                    serverSideErrorsValidator('animalTypeId', serverSideErrorsProvider)])],
            animalSubTypeId: [val.animalSubTypeId, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent); }),
                    serverSideErrorsValidator('animalSubTypeId', serverSideErrorsProvider),
                ])],
            nomenclatureAnimalPinId: val.nomenclatureAnimalPinId,
            pin: [val.pin, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent); }),
                    serverSideErrorsValidator('pin', serverSideErrorsProvider)
                ])],
            caption: [val.caption, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return !EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent); }),
                    serverSideErrorsValidator('caption', serverSideErrorsProvider)
                ])],
            birthDate: [val.birthDatePrettify, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, (function (ctrl) { return ctrl.parent &&
                        (EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent) ||
                            (!EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent) && +ctrl.parent.get('birthDateKind').value === 1)); })),
                    FormHelper.validateDateTimePicker(),
                    serverSideErrorsValidator('birthDate', serverSideErrorsProvider),
                ])],
            birthDateKind: val.birthDateKind || 1,
            birthDateYear: [val.birthDateYear, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent &&
                        !EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent) &&
                        +ctrl.parent.get('birthDateKind').value === 3; }),
                    Validators.min(1917), Validators.max(2100)
                ])],
            birthDateMonth: [val.birthDateMonth, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent &&
                        !EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent) &&
                        +ctrl.parent.get('birthDateKind').value === 3; }),
                    Validators.min(1), Validators.max(12)
                ])],
            birthDateDay: [val.birthDateDay, Validators.compose([Validators.min(1), Validators.max(31)])],
            birthDate2Year: [val.birthDate2Year, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent &&
                        !EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent) &&
                        +ctrl.parent.get('birthDateKind').value === 3; }),
                    Validators.min(1917), Validators.max(2100)
                ])],
            birthDate2Month: [val.birthDate2Month, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent &&
                        !EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent) &&
                        +ctrl.parent.get('birthDateKind').value === 3; }),
                    Validators.min(1), Validators.max(12)
                ])],
            birthDate2Day: [val.birthDate2Day, Validators.compose([Validators.min(1), Validators.max(31)])],
            deathDate: [val.deathDate, Validators.compose([
                    FormHelper.validateDateTimePicker(),
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('deathReasonId').value; }),
                    serverSideErrorsValidator('deathDate', serverSideErrorsProvider),
                ])],
            deathReasonId: [val.deathReasonId, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('deathDate').value; }),
                    serverSideErrorsValidator('deathReasonId', serverSideErrorsProvider),
                ])],
            identityDocSerial: val.identityDocSerial,
            identityDocNumber: val.identityDocNumber,
            identityDocDate: [val.identityDocDate, Validators.compose([
                    FormHelper.validateDateTimePicker(),
                    serverSideErrorsValidator('identityDocDate', serverSideErrorsProvider),
                ])],
            identityDocIssuer: val.identityDocIssuer,
            color: val.color,
            animalBreedId: val.animalBreedId,
            gender: [val.gender, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent); }),
                    serverSideErrorsValidator('gender', serverSideErrorsProvider),
                ])],
            originCountry: val.originCountry,
            totalCount: val.totalCount,
            animalReasonId: val.animalReasonId,
            animalReasonText: val.animalReasonText,
            currentAgentId: val.currentAgentId,
            currentStableId: val.currentStableId,
            weight: [val.weight, FormHelper.getApplicationMoneyValidatorDot()],
            height: [val.height, FormHelper.getApplicationMoneyValidatorDot()],
            trappedToQuarantine: val.trappedToQuarantine,
            mercuryUuid: isClone ? undefined : val.mercuryUuid,
            mercuryJournalNo: isClone ? undefined : val.mercuryJournalNo,
            animalSubtypesWithProduct: fb.array(val.animalSubtypesWithProduct || []),
            counts: EditAnimalEventCommonFormComponent.buildAnimalEventCountsArray(fb, serverSideErrorsProvider, [], function () { return group; }, true, val.animalGroup.value),
        });
        var lastAnimalTypeId = val.animalTypeId;
        group.get('animalTypeId').valueChanges.subscribe(function () {
            if (lastAnimalTypeId !== group.get('animalTypeId').value) {
                group.get('animalSubTypeId').setValue(undefined);
                group.get('animalBreedId').setValue(undefined);
                lastAnimalTypeId = group.get('animalTypeId').value;
            }
        });
        return group;
    };
    EditAnimalCommonFormComponent.isRequiredDateField = function (formGroup) {
        return !formGroup.get('animalGroup').value;
    };
    EditAnimalCommonFormComponent.isSingleAnimal = function (parent) {
        return parent && !parent.get('animalGroup').value;
    };
    Object.defineProperty(EditAnimalCommonFormComponent.prototype, "animalSubTypeId", {
        get: function () {
            return this.contextFormGroup.get('animalSubTypeId').value;
        },
        enumerable: true,
        configurable: true
    });
    EditAnimalCommonFormComponent.prototype.ngOnChanges = function () {
        var _this = this;
        if (this.contextFormGroup) {
            this.contextFormGroup.get('animalSubTypeId').valueChanges.subscribe(function () { return _this.searchPinTemplate(); });
        }
    };
    EditAnimalCommonFormComponent.prototype.isSingleAnimal = function () {
        return EditAnimalCommonFormComponent.isSingleAnimal(this.contextFormGroup);
    };
    EditAnimalCommonFormComponent.prototype.addAnimalAgent = function () {
        this.navigationService.performProductCreation(undefined, undefined, this.contextFormGroup.get('id').value, 1 /* Перевозка со сменой владельца */);
    };
    EditAnimalCommonFormComponent.prototype.addAnimalStable = function () {
        this.navigationService.performProductCreation(undefined, undefined, this.contextFormGroup.get('id').value, 1 /* Перевозка со сменой владельца */);
    };
    Object.defineProperty(EditAnimalCommonFormComponent.prototype, "newCardCounts", {
        get: function () {
            return this.contextFormGroup.get('counts');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditAnimalCommonFormComponent.prototype, "counts", {
        get: function () {
            if (!this.contextFormGroup || !this.contextFormGroup.get('id').value) {
                return [];
            }
            if (!this.contextFormGroup.countsArr) {
                this.contextFormGroup.countsArr = [];
                this.refreshAnimalCounts();
            }
            return this.contextFormGroup.countsArr;
        },
        enumerable: true,
        configurable: true
    });
    EditAnimalCommonFormComponent.prototype.refreshAnimalCounts = function () {
        var _this = this;
        if (!this.contextFormGroup || !this.contextFormGroup.get('id').value) {
            return;
        }
        var animalId = this.contextFormGroup.get('id').value;
        this.animalDataService.getAnimalCountsOnDate(animalId, DateHelper.addDays(new Date(), 1), undefined)
            .subscribe({
            next: function (countsArr) {
                _this.contextFormGroup.countsArr = countsArr;
            }
        });
    };
    EditAnimalCommonFormComponent.prototype.navigateToEventsPage = function () {
        if (this.onNavigateEventsClicked) {
            this.onNavigateEventsClicked.emit();
        }
    };
    EditAnimalCommonFormComponent.prototype.changeAnimalAgentOrStableNoVsd = function () {
        this.navigationService.performAnimalEventCreation(this.contextFormGroup.get('id').value, 29);
    };
    EditAnimalCommonFormComponent.prototype.searchAgent = function () {
        var _this = this;
        this.navigationService.searchAgent(AgentSearchModalComponent, {
            stableId: this.contextFormGroup.get('currentStableId').value,
        }).subscribe(function (val) {
            if (val) {
                _this.contextFormGroup.get('currentAgentId').setValue(val);
                _this.contextFormGroup.markAsDirty();
            }
        });
    };
    EditAnimalCommonFormComponent.prototype.searchStable = function () {
        var _this = this;
        this.cacheService.getAgent(this.contextFormGroup.get('currentAgentId').value).subscribe(function (agent) {
            _this.navigationService.searchStable(StableSearchModalComponent, {
                owner: agent.id ? agent.caption : '',
                agentId: agent.id
            })
                .subscribe(function (val) {
                if (val) {
                    _this.contextFormGroup.get('currentStableId').setValue(val);
                    _this.contextFormGroup.markAsDirty();
                }
            });
        });
    };
    EditAnimalCommonFormComponent.prototype.searchPinTemplate = function () {
        var _this = this;
        if (!this.animalSubTypeId) {
            this.placeholderPinTemplate = undefined;
            return;
        }
        combineLatest([
            this.lookupService.getLookupObj('nomenclature-animal-pin'),
            this.lookupService.getLookupObj('animal-subtype' + this.contextFormGroup.get('animalTypeId').value)
        ]).subscribe(function (_a) {
            var nomenclatureAnimalPinLookup = _a[0], animalSubtypeLookup = _a[1];
            var animalSubtype = animalSubtypeLookup['Obj' + _this.animalSubTypeId];
            if (!animalSubtype || !animalSubtype.nomenclatureAnimalPinId) {
                _this.placeholderPinTemplate = undefined;
                return;
            }
            var nm = nomenclatureAnimalPinLookup['Obj' + animalSubtype.nomenclatureAnimalPinId];
            _this.placeholderPinTemplate = nm ? nm.pinTemplate : undefined;
        });
    };
    EditAnimalCommonFormComponent.prototype.getCaptionAge = function () {
        if (!this.contextFormGroup.get('birthDate').value) {
            return '';
        }
        var age = DateHelper.durationYearMonthDay(new Date(this.contextFormGroup.get('birthDate').value), this.contextFormGroup.get('deathDate').value ? this.contextFormGroup.get('deathDate').value : new Date(), true);
        return age ? 'Возраст: ' + age : '';
    };
    EditAnimalCommonFormComponent.prototype.isRequiredDateField = function (formGroup) {
        return EditAnimalCommonFormComponent.isRequiredDateField(formGroup);
    };
    EditAnimalCommonFormComponent.prototype.getCertTitle = function (productInfo) {
        return productInfo.transactionType === 4
            ? 'Производственный сертификат: ' + productInfo.animalSubtypeCaption
            : 'Транспортный сертификат: ' + productInfo.animalSubtypeCaption;
    };
    return EditAnimalCommonFormComponent;
}());
export { EditAnimalCommonFormComponent };
