import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators';
import { SecurityService } from './security.service';
import {StringHelper} from '../../helpers/string-helper';

@Injectable({
  providedIn: 'root',
})
export class ProductDataService {

  constructor(private http: HttpClient,
              private securityService: SecurityService) {
  }

  public search(searchParams: any): Observable<any[]> {
    const qstring = environment.api + '/product/search';
    return this.http.post(qstring, searchParams)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getProductExpertises(productId: any): Observable<any[]> {
    const qstring = environment.api + '/product-expertise/search/' + productId;
    return this.http.post(qstring, {}, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public searchBySourceProduct(productId: any): Observable<any[]> {
    const qstring = environment.api + '/product/search-by-source-product/' + productId;
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getProductCommonForEdit(id: number): Observable<any> {
    const qstring = environment.api + '/product/' + id.toString(10);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((item: any) => {
        return item.data;
      }));
  }

  getProductCommonForEdit2(ids: number[]): Observable<any> {
    const qstring = environment.api + '/product';
    return this.http.post(qstring, ids)
      .pipe(map((item: any) => {
        return item.data;
      }));
  }

  backgroundUpdateProductFromMercury(id: number): Observable<any> {
    const qstring = environment.api + '/product/background-update-from-mercury/' + id.toString(10);
    return this.http.get(qstring)
      .pipe(map((item: any) => {
        return item;
      }));
  }

  backgroundUpdateProductFromMercury2(ids: number[]): Observable<any> {
    const qstring = environment.api + '/product/background-update-from-mercury/';
    return this.http.post(qstring, ids)
      .pipe(map((item: any) => {
        return item;
      }));
  }

  getProductExpertiseForEdit(productId: number, expertiseId: number): Observable<any> {
    const qstring = environment.api + '/product-expertise/' + productId.toString() + '/' + expertiseId.toString();
    return this.http.get(qstring).pipe(map((item: any) => {
      return item.data;
    }));
  }

  getProductCommonForLookup(id: number): Observable<any> {
    const qstring = environment.api + '/product/lookup/' + id.toString(10);
    return this.http.get(qstring);
  }

  getProductCommonForLookup2(ids: number[]): Observable<any[]> {
    const qstring = environment.api + '/product/lookup';
    return this.http.post(qstring, ids)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getProductExpertiseForLookup(ids: number[]): Observable<any[]> {
    const qstring = environment.api + '/product-expertise/lookup';
    return this.http.post(qstring, ids)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  storeProductCommonInfo(model: any) {

    const retVal = new Subject<any>();

    this.securityService.getUserInfo().subscribe(userInfo => {

      const dataToStore = model;
      if (+dataToStore.templateVisibility === 2) {
        dataToStore.templateInstitutionId = userInfo.institutionId;
        dataToStore.templateInstitutionEmployeeId = undefined;
      } else if (+dataToStore.templateVisibility === 3) {
        dataToStore.templateInstitutionId = userInfo.institutionId;
        dataToStore.templateInstitutionEmployeeId = userInfo.institutionEmployeeId;
      } else {
        dataToStore.templateInstitutionId = undefined;
        dataToStore.templateInstitutionEmployeeId = undefined;
      }

      const qstring = environment.api + '/product/store-common';
      this.http.post(qstring, model).subscribe((response: any) => {
        retVal.next(response.data);
      }, error => {
        retVal.error(error);
      });
    });

    return retVal;
  }

  checkRegionalization(model: any): Observable<any[]> {
    const qstring = environment.api + '/product/check-regionalization';
    return this.http.post(qstring, model)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getForCreateProductExpertise(expertiseId: number, productId: number) {
    const qstring = environment.api + '/product-expertise/get-for-create';
    return this.http.post(qstring, { expertiseId: expertiseId, productId: productId })
      .pipe(map((response: any) => {
      return response as any;
    }));
  }

  storeProductExpertiseInfo(model: any, startSync: boolean) {
    const qstring = environment.api + '/product-expertise/store-common' + (startSync ? '/with-sync' : '');
    return this.http.post(qstring, model).pipe(map((item: any) => {
      return item.data;
    }));
  }

  deleteProduct(productId: number): Observable<any> {
    const qstring = environment.api + '/product/delete/' + productId.toString();
    return this.http.post(qstring, {});
  }

  deleteProductExpertise(productId: number, expertiseId: number): Observable<any> {
    const qstring = environment.api + '/product-expertise/delete/' + productId.toString() + '/' + expertiseId.toString();
    return this.http.post(qstring, {});
  }

  sendToRegistration(productIds: any[]): Observable<any> {
    const qstring = environment.api + '/product/send-to-registration';
    return this.http.post(qstring, {productIds: productIds});
  }

  sendToDeletion(productId: number, reason: string): Observable<any> {
    const qstring = environment.api + '/product/send-to-deletion/' + productId.toString();
    return this.http.post(qstring, reason);
  }

  sendToClearance(data: any): Observable<any> {
    const qstring = environment.api + '/product/send-to-clearance';
    return this.http.post(qstring, data);
  }

  getStableProducts(stableId: any, dateFrom: any, dateTo: any): Observable<any[]> {
    const qstring = environment.api + '/product/search-by-stable/' + stableId + '/' +
      StringHelper.getISODate(dateFrom) + '/' + StringHelper.getISODate(dateTo);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getDateAndTimeByStableIdDistinct(stableId: any): Observable<any[]> {
    const qstring = environment.api + '/product/search-by-stable/get-dates/' + stableId;
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getStableProductsTemplates(stableId: any): Observable<any[]> {
    const qstring = environment.api + '/product/search-by-stable/templates/' + stableId;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getAnimalProducts(animalId: any, showHistory: any): Observable<any[]> {
    const qstring = environment.api + '/product/search-by-animal/' + animalId + '/' + !!showHistory;
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getProductForCreate(params: any) {
    const qstring = environment.api + '/product/get-for-create';
    return this.http.post(qstring, params).pipe(map((item: any) => {
      return item.data;
    }));
  }

  getTransportDocumentForCreate(transactionTypeId: number, sourceProductId: number) {
    const qstring = environment.api + `/product/get-transport-document-for-create/${transactionTypeId}/${sourceProductId}`;
    return this.http.post(qstring, {}).pipe(map((item: any) => {
      return item.data;
    }));
  }

  getTransportDocumentForCreate2(transactionTypeId: number, sourceProductIds: number[]) {
    const qstring = environment.api + `/product/get-transport-document-for-create/${transactionTypeId}`;
    return this.http.post(qstring, sourceProductIds).pipe(map((item: any) => {
      return item.data;
    }));
  }

  getByTransactionId(transactionId: number): Observable<any[]> {
    const qstring = environment.api + '/product/by-transaction/' + transactionId.toString();
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  startSyncDecisionMakingProductRequest(data: any): Observable<any> {
    const qstring = environment.api + '/product/product-request/decision-making';
    return this.http.post(qstring, data);
  }
}
