import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { SecurityService } from '../../../logic/services/security.service';
import { EditAnimalComponent } from '../edit-animal.component';
import { StringHelper } from '../../../helpers/string-helper';
import {DateHelper} from '../../../helpers/date-helper';

@Component({
  selector: 'app-animal-search-results-form',
  templateUrl: './animal-search-results-form.component.html'
})
export class AnimalSearchResultsFormComponent implements OnChanges {

  @Output() rowClicked = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @Input() model = AnimalSearchResultsFormComponent.getDefaultModel();
  @Input() addManyAnimals = false;

  isInvalid = FormHelper.isInvalid;

  gridColumnDefs = AnimalSearchResultsFormComponent.getGridColumnDefs();

  public static getGridColumnDefs(addCheckboxSelection = false) {
    return [
      {
        headerName: '', field: '', width: 50, hide: !addCheckboxSelection,
        checkboxSelection: addCheckboxSelection, headerCheckboxSelection: addCheckboxSelection,
        headerCheckboxSelectionFilteredOnly: true,
      },
      {
        headerName: '№', field: 'idNumber', width: 130,
        cellRenderer: params => {
          return !params.data.tempId
            ? (params.data.hasWaitingConfirmedChanges
              ? `<clr-icon shape="hourglass" style="color:#fb8c00" title="Неподтверждённые изменения"></clr-icon>&nbsp;`
              : '') + params.value || params.data.id
            : `<clr-icon shape="hourglass" style="color:#fb8c00" title="Неподтверждённая карточка"></clr-icon>`;
        },
      },
      {
        headerName: 'Сертификат', field: 'hasStockEntryRecordTitle', width: 50,
        cellRenderer: params => {
          return params && params.data && params.data.hasStockEntryRecord
            ? `<clr-icon shape="certificate" style="color:green" title="Имеется сертификат"></clr-icon>`
            : ``;
        },
        filterValueGetter: params => params.data.hasStockEntryRecord ? 'есть сертификат' : 'нет сертификата',
        cellStyle: {'padding-left': '17px'},
      },
      {
        headerName: 'Убой', field: 'examResultTitle', width: 50,
        cellRenderer: params => {
          return params && params.data && params.data.examResult
            ? [1].includes(+params.data.examResult)
              ? `<clr-icon shape="no-access" title="Запрещено на убой"></clr-icon>`
              : `<clr-icon shape="success-standard" style="color:green" title="Разрешено на убой"></clr-icon>`
            : ``;
        },
        filterValueGetter: params => params.data.examResult === 1
          ? 'Запрещено на убой'
          : params.data.examResult === 2 ? 'Разрешено на убой' : '',
        cellStyle: {'padding-left': '17px'},
      },
      {
        headerName: 'Наименование', field: 'caption', width: 250,
        filterParams: {
          cellRenderer: params => {
            if (params.value) {
              return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
            } else {
              return '(Пусто)';
            }
          }
        },
        cellRenderer: params => {

          const animalStateHtml = params.data.overdueEventsCount && params.data.overdueEventsCount > 0
            ? '<clr-icon shape="history" style="color:red"' +
              ' title="Имеются просроченные мероприятия: ' + params.data.overdueEventsCount + '"></clr-icon>'
            : '<clr-icon shape="check" style="color:green" title="Просроченные мероприятия отсутствуют"></clr-icon>';

          return animalStateHtml + '&nbsp;' + (params.data.animalGroup ? '<clr-icon shape="layers"></clr-icon>&nbsp;' : '') + params.value;
        }
      },
      {
        headerName: 'Состояние животного',
        field: 'overdueEventsCount',
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        headerName: 'Кол-во', field: 'totalCount', width: 70,
      },
      {
        headerName: 'Вид', field: 'animalTypeIdCaption', width: 150,
      },
      {
        headerName: 'Подвид', field: 'animalSubtypeIdCaption', width: 150,
        filterParams: {
          cellRenderer: params => {
            if (params.value) {
              return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
            } else {
              return '(Пусто)';
            }
          }
        },
      },
      {
        headerName: 'Возраст', field: 'birthDate', width: 250,
          cellRenderer: params => {
          if (!params.value) {
            return '';
          }
            const age = DateHelper.durationYearMonthDay(new Date(params.value),
              params.data.deathDate || new Date(), true);

            return age || '';
            }
      },
      {
        headerName: 'Местонахождение', field: 'stable', width: 250,
        filterParams: {
          cellRenderer: params => {
            if (params.value) {
              return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
            } else {
              return '(Пусто)';
            }
          }
        },
      },
      {
        headerName: 'Владелец', field: 'owner', width: 200,
        filterParams: {
          cellRenderer: params => {
            if (params.value) {
              return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
            } else {
              return '(Пусто)';
            }
          }
        },
        rowGroupingSettings: {
          name: 'Владельцу',
          renderParams: {
            captionGetter: row => row.value,
            getChildrenCount: node => EditAnimalComponent.getCountForGroup(node),
          }
        }
      },
    ];
  }

  public static getDefaultModel(fb?: FormBuilder, filterParams: any = {}) {
    return {
      searchResults: [],
      filterFormGroup: fb ? AnimalSearchResultsFormComponent.buildSearchForm(fb, filterParams) : undefined
    };
  }

  public static buildSearchForm(fb: FormBuilder, filterParams: any = {}) {

    const fg = fb.group({
      caption: filterParams.caption,
      owner: filterParams.owner,
      agentId: filterParams.agentId,
      stableId: filterParams.stableId,
      address: filterParams.address,
      cadNo: filterParams.cadNo,
      showDeleted: false,
      animalTypeId: filterParams.animalTypeId,
      animalSubtypeId: filterParams.animalSubtypeId,
      toggleConfirmedOrExternal: true,
      availableShowExternal: filterParams.availableShowExternal,
      showExternal: false,
    });

    fg.controls['animalTypeId'].valueChanges.subscribe(() => {
      fg.controls['animalSubtypeId'].setValue(undefined);
    });

    return fg;
  }

  constructor(private animalDataService: AnimalDataService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              public appNavigationService: AppNavigationService,
              private lookupSourceService: LookupSourceService,
              public securityService: SecurityService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.gridColumnDefs[0].checkboxSelection = this.addManyAnimals;
    this.gridColumnDefs[0].headerCheckboxSelection = this.addManyAnimals;
    this.gridColumnDefs[0].headerCheckboxSelectionFilteredOnly = this.addManyAnimals;
  }

  refreshResults() {

    if (!this.model.filterFormGroup.valid) {
      return;
    }

    this.globalWaitingOverlayService.StartWaiting();

    this.lookupSourceService.getLookupObj('animal-type').subscribe(animalTypeLookup => {
      this.lookupSourceService.getLookupObj('animal-subtype').subscribe(animalSubtypeIdLookup  => {
      this.animalDataService.search(this.model.filterFormGroup.value)
        .subscribe({
          next: data => {

            data.forEach(el => {
              const animalSubtype = (animalSubtypeIdLookup[el.animalTypeId] || []).find(x => x.id === el.animalSubtypeId);
              el.animalTypeIdCaption = el.animalTypeId ? animalTypeLookup[el.animalTypeId] : '<<Не указан>>';
              el.animalSubtypeIdCaption = animalSubtype ? animalSubtype.caption : '<<не указан>>';

              el.totalCount = el.totalCount || 1;
            });

            this.model.searchResults = data;
            this.selectionChanged.emit([]);
            this.globalWaitingOverlayService.EndWaiting();
          }, error: () => {
            this.globalWaitingOverlayService.EndWaiting();
          }
        });
      });
    });
  }

  filterEnterPressed($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.refreshResults();
    }
  }

  getRowStyle(params: any) {
    if (params.data && (params.data.deathDate || (params.data.animalGroup && !params.data.totalCount))) {
      return {color: 'silver'};
    }
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  onGridSelectionChanged(params: any) {

    const selectedRows = params.api.getSelectedRows();

    const selectedIds = [];

    selectedRows.forEach(row => {
      selectedIds.push(row.id);
    });

    this.selectionChanged.emit(selectedIds);
  }

  changeToggleConfirmedOrExternal() {
    this.model.filterFormGroup.get('toggleConfirmedOrExternal')
      .setValue(!this.model.filterFormGroup.get('toggleConfirmedOrExternal').value);
    this.model.filterFormGroup.get(this.model.filterFormGroup.get('toggleConfirmedOrExternal').value ? 'showExternal' : 'showDeleted')
      .setValue(null);
  }
}
