import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { DrugSearchModalComponent } from '../../edit-drug/search/drug-search-modal.component';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { DateHelper } from '../../../helpers/date-helper';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { StableSearchModalComponent } from '../../edit-stable/search/stable-search-modal.component';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { AnimalSearchModalComponent } from '../search/animal-search-modal.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { QuarantineDataService } from '../../../logic/services/quarantine-data.service';
import { GridApi } from '@ag-grid-community/core/dist/es6/gridApi';
import { AppGridEditableComponent } from '../../../ui/controls/app-grid-editable.component';
import { EditDrugCommonFormComponent } from '../../edit-drug/common/edit-drug-common-form.component';
import { RowNode } from '@ag-grid-community/core/dist/es6/entities/rowNode';

@Component({
  selector: 'app-edit-animal-event-common-form',
  templateUrl: './edit-animal-event-common-form.component.html'
})
export class EditAnimalEventCommonFormComponent implements OnChanges {
  @Input() contextFormGroup: AppFormGroup;
  @Input() model: any;

  @ViewChild('appGridSingleChild')
  appGridSingleChild: AppGridEditableComponent;
  @ViewChild('appGridGroupChild')
  appGridGroupChild: AppGridEditableComponent;

  isInvalid = FormHelper.isInvalid;
  changeDecimalStrValue = EditDrugCommonFormComponent.changeDecimalStrValue;
  @Input() animal: any;
  @Input() eventType: any;
  @Input() showAddManyAnimal = false;
  @Input() trappedToQuarantine: any[] = [];
  @Output() resultsCountsChanged = new EventEmitter();

  availableDisease: any[];
  availableEvents: any[];
  availableResults: any[];

  addAllAvailableDiseases = false;

  equalsSome = FormHelper.equalsSome;

  gridApiGroup!: GridApi;
  gridApiSingle!: GridApi;
  gridApiStableAnimals!: GridApi;

  gridColumnDefs = [
    {
      headerName: '', field: '', width: 50,
      checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: '№', field: 'idNumber', width: 130,
      cellRenderer: params => {
        return params.value || params.data.id;
      },
    },
    {
      headerName: 'Сертификат', field: 'hasStockEntryRecordTitle', width: 50,
      cellRenderer: params => {
        return params && params.data && params.data.hasStockEntryRecord
          ? `<clr-icon shape="certificate" style="color:green" title="Имеется сертификат"></clr-icon>`
          : ``;
      },
      filterValueGetter: params => params.data.hasStockEntryRecord ? 'есть сертификат' : 'нет сертификата',
      cellStyle: {'padding-left': '17px'},
    },
    {
      headerName: 'Наименование', field: 'caption', width: 250,
      cellRenderer: params => {
        return (params.data.animalGroup ? '<clr-icon shape="layers"></clr-icon>&nbsp;' : '') + params.value;
      }
    },
    {
      headerName: 'Вид', field: 'animalTypeIdCaption', width: 150,
    },
    {
      headerName: 'Подвид', field: 'animalSubtypeIdCaption', width: 150,
    },
    {
      headerName: 'Возраст', field: 'birthDate', width: 250,
      cellRenderer: params => {
        if (!params.value) {
          return '';
        }
        const age = DateHelper.durationYearMonthDay(new Date(params.value),
          params.data.deathDate || new Date(), true);

        return age || '';
      }
    },
  ];

  gridResultSingleColDefs = [
    {
      headerName: 'Наименование',
      field: 'animalId',
      lookupName: 'animal',
      editorType: AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH,
      width: 335,
    },
    {
      headerName: 'Результат', field: 'result',
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_LOOKUP,
      lookupName: 'exam-result',
      disabledChoices: () => this.eventType.eventKind === 1 ? [4] : [],
      width: 150,
      required: () => true,
      editable: () => true,
    },
    {
      headerName: 'Состояние', field: 'state',
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_LOOKUP,
      lookupName: 'animal-state',
      width: 150,
      required: () => true,
      editable: () => true,
    },
    {
      headerName: 'Причина запрета на убой', field: 'reasonIsolated',
      width: 390, tooltipField: 'reasonIsolated',
      hide: true,
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      editable: (params) => {
        return +params.data.result === 1 && this.eventType.id === 32;
      },
    },
  ];

  gridResultGroupColDefs = [
    {
      headerName: 'Наименование',
      field: 'animalId',
      lookupName: 'animal',
      editorType: AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH,
      width: 290,
    },
    {
      headerName: 'Подвид',
      field: 'animalSubTypeId',
      width: 190,
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_LOOKUP,
      lookupName: 'animal-subtype',
      parentFieldLookup: 'animalTypeId',
      requiredValue2: true,
    },
    {
      headerName: 'Пол.', headerTooltip: 'Положительно', field: 'countPositive', width: 90,
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      keyPressCallback: FormHelper.processMoneyKeypress,
      editable: () => true,
    },
    {
      headerName: 'Отр.', headerTooltip: 'Отрицательно', field: 'countNegative', width: 90,
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      keyPressCallback: FormHelper.processMoneyKeypress,
      editable: () => true,
    },
    {
      headerName: 'Среаг.', headerTooltip: 'Среагировало', field: 'countRespond', width: 90,
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      keyPressCallback: FormHelper.processMoneyKeypress,
      editable: () => true,
    },
    {
      field: 'countExamined', width: 90,
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      keyPressCallback: FormHelper.processMoneyKeypress,
      editable: () => true,
      headerValueGetter: () => this.getCaptionExamined(true),
      headerTooltip: () => this.getCaptionExamined(),
    },
    {
      field: 'countIsolated', width: 90,
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      keyPressCallback: FormHelper.processMoneyKeypress,
      editable: () => true,
      headerValueGetter: () => this.getCaptionIsolated(true),
    },
    {
      headerName: 'Изм.пог.', field: 'countDiff', width: 90,
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      editable: () => true,
      valueSetter: params => {
        if (params.newValue && Number.isInteger(+params.newValue)) {
          params.data.get('countDiff').setValue(+params.newValue);
        } else {
          params.data.get('countDiff').setValue(null);
        }
        return true;
      },
    },
    {
      headerName: 'Причина запрета на убой', field: 'reasonIsolated', width: 150, tooltipField: 'reasonIsolated',
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      hide: true,
      editable: (params) => {
        return +params.data.get('countIsolated').value > 0 && this.eventType.id === 32;
      },
    },
  ];

  animalTypeIdLookup: any = {};

  lastLoadedCountsDate: any;
  boundContextFormGroup: AppFormGroup;

  processMoneyKeypress = FormHelper.processMoneyKeypressDot;
  processMoneyKeypressDrug = FormHelper.processMoneyKeypress;

  public static createFormGroup(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any,
                                animalCountsProvider: () => any) {
    const group = fb.group({
      id: val.id,
      createdTime: val.createdTime,
      createdUser: val.createdUser,
      date: [val.date, Validators.compose([FormHelper.validateDateTimePicker(),
        serverSideErrorsValidator('date', serverSideErrorsProvider)])],
      datePlanning: [val.datePlanning, Validators.compose([FormHelper.validateDateTimePicker(),
        serverSideErrorsValidator('datePlanning', serverSideErrorsProvider)])],
      animalId: val.animalId,
      stableId: val.stableId,
      eventTypeId: [val.eventTypeId, Validators.compose([Validators.required,
        serverSideErrorsValidator('eventTypeId', serverSideErrorsProvider)])],
      diseaseTypeId: [val.diseaseTypeId, Validators.compose([
        serverSideErrorsValidator('diseaseTypeId', serverSideErrorsProvider)])],
      diseaseSerotypeId: val.diseaseSerotypeId,
      eventKind: val.eventKind,
      animalGroup: val.animalGroup || false,
      examResult: [val.examResult, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent
          && FormHelper.equalsSome(+ctrl.parent.get('eventKind').value, 1, 2, 3)
          && !ctrl.parent.get('animalGroup').value
          && ctrl.parent.get('id').value
          && ctrl.parent.get('date').value),
        serverSideErrorsValidator('examResult', serverSideErrorsProvider),
      ])],
      examNo: [val.examNo, Validators.compose([
        serverSideErrorsValidator('examNo', serverSideErrorsProvider)])],
      testMaterialId: val.testMaterialId,
      animalState: [val.animalState, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent
          && FormHelper.equalsSome(+ctrl.parent.get('eventKind').value, 1, 2, 3)
          && !ctrl.parent.get('animalGroup').value
          && ctrl.parent.get('id').value
          && ctrl.parent.get('date').value),
        serverSideErrorsValidator('animalState', serverSideErrorsProvider),
      ])],
      slaughterPlace: val.slaughterPlace,
      sanitarySlaughter: val.sanitarySlaughter,
      slaughterInstitutionId: [val.slaughterInstitutionId, Validators.compose([
        serverSideErrorsValidator('slaughterInstitutionId', serverSideErrorsProvider)])],
      temperature: [val.temperature, Validators.compose([FormHelper.validateMoney(),
        serverSideErrorsValidator('temperature', serverSideErrorsProvider)])],
      temperatureTo: [val.temperatureTo, Validators.compose([FormHelper.validateMoney(),
        serverSideErrorsValidator('temperatureTo', serverSideErrorsProvider)])],
      rangeTemperature: !!val.temperatureTo,
      mucousCondition: val.mucousCondition,
      useRestriction: val.useRestriction,
      restrictedAmountKg: [val.restrictedAmountKg, Validators.compose([FormHelper.validateMoney(),
          serverSideErrorsValidator('restrictedAmountKg', serverSideErrorsProvider)])],
      restrictedSubamountKg: [val.restrictedSubamountKg, Validators.compose([FormHelper.validateMoney(),
        serverSideErrorsValidator('restrictedSubamountKg', serverSideErrorsProvider)])],
      eventIndicatorId: [val.eventIndicatorId, Validators.compose([
        serverSideErrorsValidator('eventIndicatorId', serverSideErrorsProvider)])],
      laboratoryInstitutionId: [val.laboratoryInstitutionId, Validators.compose([
        serverSideErrorsValidator('laboratoryInstitutionId', serverSideErrorsProvider)])],
      secondary: val.secondary,
      countPositive: [val.countPositive, Validators.pattern(/^\d{1,9}$/)],
      countNegative: [val.countNegative, Validators.pattern(/^\d{1,9}$/)],
      countRespond: [val.countRespond, Validators.pattern(/^\d{1,9}$/)],
      countExamined: [val.countExamined, Validators.pattern(/^\d{1,9}$/)],
      countIsolated: [val.countIsolated, Validators.pattern(/^\d{1,9}$/)],
      reasonIsolated: val.reasonIsolated,
      animalSubTypeId: val.animalSubTypeId,
      animalTypeId: val.animalTypeId,
      comments: val.comments,
      drugs: EditAnimalEventCommonFormComponent.getDrugsArray(fb, val.drugs),
      diagnosticTypeResults: EditAnimalEventCommonFormComponent.getDiagnosticTypeResultsArray(fb, val.diagnosticTypeResults),
      animals: fb.array(val.animals || []),
      animalEventSingleResults: EditAnimalEventCommonFormComponent.getAnimalEventSingleResultsArray(fb,
        serverSideErrorsProvider, val.animalEventSingleResults),
      animalEventGroupResults: EditAnimalEventCommonFormComponent.getAnimalEventGroupResultsArray(fb,
        serverSideErrorsProvider, val.animalEventGroupResults),
      counts: EditAnimalEventCommonFormComponent.buildAnimalEventCountsArray(fb, serverSideErrorsProvider, val.counts,
        animalCountsProvider),
      otherAgentId: val.otherAgentId,
      otherStableId: val.otherStableId,
      otherAnimalId: val.otherAnimalId,
      productId: val.productId,
      mercuryId: val.mercuryId,
      syncError: val.syncError,
      animalStableMode: false,
      performedByAgent: val.performedByAgent,
      quarantineId: val.quarantineId,
      files: (val.files && val.files.length
        ? fb.array(val.files.map((el, ix) => fb.group(this.buildFormGroupFile(fb, serverSideErrorsProvider,
          el || {}, 'files-' + ix))))
        : fb.array([])),
      notExecuted: val.notExecuted || false,
      stables: fb.array(val.stables || []),
      diseases: fb.array(val.diseases || []),
      square: [val.square, FormHelper.getApplicationMoneyValidatorDot()],
      deathReasonId: [val.deathReasonId, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && +ctrl.parent.get('eventTypeId').value === 29),
        serverSideErrorsValidator('deathReasonId', serverSideErrorsProvider)
      ])],
      syncWithJournalNumbers: fb.array(val.syncWithJournalNumbers || []),
      isForced: val.isForced || false,
      forcedReasonId: [val.forcedReasonId, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && ctrl.parent.get('isForced').value),
        serverSideErrorsValidator('forcedReasonId', serverSideErrorsProvider)])],
    });

    return group;
  }

  public static buildAnimalEventCountsArray(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider,
                                            val: any[], animalCountsProvider: () => any,
                                            isForCreateAnimal?: boolean, isGroupAnimal?: boolean) {

    if (!val || !val.length) {
      val = [];
    }

    const countsArray = fb.array(val.map(count => EditAnimalEventCommonFormComponent
      .buildAnimalEventCount(fb, count, isForCreateAnimal, isGroupAnimal)));

    countsArray.valueChanges.subscribe(() => {
      let i = 0;
      while (i < countsArray.length - 1) {
        if (!countsArray.controls[i].get('animalSubTypeId').value) {
          countsArray.removeAt(i);
        } else {
          i++;
        }
      }

      if (countsArray.length && countsArray.controls[countsArray.length - 1].get('animalSubTypeId').value) {
        countsArray.push(EditAnimalEventCommonFormComponent.buildAnimalEventCount(
          fb, animalCountsProvider() || {}, isForCreateAnimal, isGroupAnimal));
      }
    });

    countsArray.push(EditAnimalEventCommonFormComponent.buildAnimalEventCount(
      fb, animalCountsProvider() || {}, isForCreateAnimal, isGroupAnimal));

    return countsArray;
  }

  public static buildAnimalEventCount(fb: FormBuilder, animalCount: any, isForCreateAnimal?: boolean, isGroupAnimal?: boolean) {
    return fb.group({
      animalTypeId: animalCount.animalTypeId,
      animalSubTypeId: [animalCount.animalSubTypeId, Validators.compose(
        [FormHelper.conditionalValidate(
          Validators.required, ctrl => ctrl.parent && (((ctrl.parent.parent.value.length === 1 &&
            isForCreateAnimal && !isGroupAnimal) || ctrl.parent.get('countDiff').value) || ctrl.parent.get('gender').value))])],
      countDiff: [animalCount.countDiff, Validators.compose(
        [FormHelper.conditionalValidate(
          Validators.required, ctrl => ctrl.parent && ((((ctrl.parent.parent.value.length === 1 ||
                ctrl.parent.get('animalSubTypeId').value) &&
              ctrl.parent.parent.parent && !ctrl.parent.parent.parent.controls['animalId']) ||
            ctrl.parent.get('animalSubTypeId').value) || ctrl.parent.get('gender').value)),
          Validators.pattern(/^-?\d{1,10}$/)])],
      countBefore: '',
      gender: [animalCount.gender, Validators.compose( [FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent &&
        ((((ctrl.parent.parent.value.length === 1 || ctrl.parent.get('animalSubTypeId').value) &&
            ctrl.parent.parent.parent && !ctrl.parent.parent.parent.controls['animalId']) ||
          ctrl.parent.get('animalSubTypeId').value) || ctrl.parent.get('countDiff').value))])],
    });
  }

  public static buildFormGroupFile(fb: FormBuilder,
                                   serverSideErrorsProvider: ServerSideErrorsProvider,
                                   val: any,
                                   formGroupNameRelation: string) {
    return {
      typeId: [val.typeId,
        Validators.compose([FormHelper.validateDateTimePicker,
          serverSideErrorsValidator('typeId', serverSideErrorsProvider, formGroupNameRelation),
          FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && ctrl.parent.get('uri').value)])],
      uri: [val.uri,
        Validators.compose([FormHelper.validateDateTimePicker,
          serverSideErrorsValidator('uri', serverSideErrorsProvider, formGroupNameRelation),
          FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && ctrl.parent.get('typeId').value)])],
    };
  }

  public static getDrugsArray(fb: FormBuilder, drugs: any[]): FormArray {
    if (!drugs) {
      drugs = [];
    }

    const drugsArray = fb.array(drugs.map(el => fb.group(EditAnimalEventCommonFormComponent.getDrugGroupDef(el))));

    drugsArray.valueChanges.subscribe(() => {
      let i = 0;
      while (i < drugsArray.length - 1) {
        if (!drugsArray.controls[i].get('drugId').value
          && !drugsArray.controls[i].get('drugTypeId').value) {
          drugsArray.removeAt(i);
        } else {
          i++;
        }
      }

      if (drugsArray.length && (drugsArray.controls[drugsArray.length - 1].get('drugId').value
          || drugsArray.controls[drugsArray.length - 1].get('drugTypeId').value)) {
        drugsArray.push(fb.group(EditAnimalEventCommonFormComponent.getDrugGroupDef({})));
      }
    });

    drugsArray.push(fb.group(EditAnimalEventCommonFormComponent.getDrugGroupDef({})));

    return drugsArray;
  }

  public static getDrugGroupDef(val: any): any {
    return {
      id: val.id,
      __own_money: !!val.drugTypeId,
      drugId: val.drugId,
      drugTypeId: val.drugTypeId,
      spentValue: [val.spentValue],
      spentValueStr: [val.spentValue ? val.spentValue.toString().replace('.', ',') : null,
        Validators.compose([FormHelper.validateDrugDecimal()])],
      sensibility: val.sensibility,
    };
  }

  public static getDiagnosticTypeResultsArray(fb: FormBuilder, diagnosticTypeResults: any[]): FormArray {
    if (!diagnosticTypeResults) {
      diagnosticTypeResults = [];
    }

    const eventDiagnosticTypeArray = fb.array(
      diagnosticTypeResults.map(el => fb.group(EditAnimalEventCommonFormComponent.getDiagnosticTypeResultsDef(el))));

    eventDiagnosticTypeArray.push(fb.group(EditAnimalEventCommonFormComponent.getDiagnosticTypeResultsDef({})));

    return eventDiagnosticTypeArray;
  }

  public static getDiagnosticTypeResultsDef(val: any): any {
    return {
      diagnosticTypeId: val.diagnosticTypeId,
      diagnosticResultId: val.diagnosticResultId,
      diagnosticResultValue: [val.diagnosticResultValue, FormHelper.validateMoneyMoreTwoDecimal()],
    };
  }

  public static getAnimalEventSingleResultsArray(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider,
                                                 animalEventSingleResults: any[]): FormArray {
    if (!animalEventSingleResults) {
      animalEventSingleResults = [];
    }

    return fb.array(
      animalEventSingleResults.map(el => EditAnimalEventCommonFormComponent.getAnimalEventSingleResultsDef(fb, el)));
  }

  public static getAnimalEventSingleResultsDef(fb: FormBuilder, val: any = {}) {
    return fb.group({
      animalId: val.animalId,
      caption: val.caption,
      result: val.result,
      state: val.state,
      reasonIsolated: val.reasonIsolated,
    });
  }

  public static getAnimalEventGroupResultsArray(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider,
                                                animalEventGroupResults: any[]): FormArray {
    if (!animalEventGroupResults) {
      animalEventGroupResults = [];
    }

    return fb.array(
      animalEventGroupResults.map(el => EditAnimalEventCommonFormComponent.getAnimalEventGroupResultsDef(fb, el)));
  }

  public static getAnimalEventGroupResultsDef(fb: FormBuilder, val: any = {}) {
    return fb.group({
      animalId: val.animalId,
      animalSubTypeId: val.animalSubTypeId,
      animalTypeId: val.animalTypeId,
      caption: val.caption,
      countPositive: val.countPositive,
      countNegative: val.countNegative,
      countRespond: val.countRespond,
      countExamined: val.countExamined,
      countIsolated: val.countIsolated,
      reasonIsolated: val.reasonIsolated,
      countDiff: val.countDiff,
      count: val.count,
    });
  }

  diagnosticTypeIdChange(index: any) {
    if (!this.diagnosticTypeResults.controls[index].get('diagnosticTypeId').value) {
      if (index !== this.diagnosticTypeResults.length - 1) {
        this.diagnosticTypeResults.removeAt(index);
      }
    } else {
      if (index === this.diagnosticTypeResults.length - 1) {
        this.diagnosticTypeResults.push(this.fb.group(EditAnimalEventCommonFormComponent.getDiagnosticTypeResultsDef({})));
      }
    }
  }

  get files() {
    return this.contextFormGroup.get('files') as FormArray;
  }

  get rangeTemperature() {
    return this.contextFormGroup.get('rangeTemperature').value;
  }

  constructor(public appNavigationService: AppNavigationService,
              private fb: FormBuilder,
              private animalDataService: AnimalDataService,
              private cacheService: AddressPersonFioCacheService,
              private lookupSourceService: LookupSourceService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              private dataCachingService: DataCachingService,
              private quarantineDataService: QuarantineDataService,
              private cdr: ChangeDetectorRef) {
  }

  public get drugs(): FormArray {
    return this.contextFormGroup.get('drugs') as FormArray;
  }

  public get diagnosticTypeResults(): FormArray {
    return this.contextFormGroup.get('diagnosticTypeResults') as FormArray;
  }

  public get animals(): FormArray {
    return this.contextFormGroup.get('animals') as FormArray;
  }

  public get animalEventSingleResults(): FormArray {
    return this.contextFormGroup.get('animalEventSingleResults') as FormArray;
  }

  public get animalEventGroupResults(): FormArray {
    return this.contextFormGroup.get('animalEventGroupResults') as FormArray;
  }

  public get counts(): FormArray {
    return this.contextFormGroup.get('counts') as FormArray;
  }

  searchDrug(fg: FormGroup) {
    this.appNavigationService.searchDrug(DrugSearchModalComponent, {})
      .subscribe(val => {
        if (val) {
          fg.get('drugId').setValue(val.id);
          fg.get('drugTypeId').setValue(val.drugTypeId);
          fg.markAsDirty();
        }
      });
  }

  getRowStyle(params: any) {
    if (params.data && (params.data.deathDate || (params.data.animalGroup && !params.data.totalCount))) {
      return { color: 'silver' };
    }
  }

  onGridReady(params: any) {
    this.gridApiStableAnimals = params.api;
    params.api.sizeColumnsToFit();
  }

  onGroupGridReady(params: any) {
    this.gridApiGroup = params.api;
    params.api.sizeColumnsToFit();
  }

  onSingleGridReady(params: any) {
    this.gridApiSingle = params.api;
    params.api.sizeColumnsToFit();
  }

  onGridSelectionChanged(params: any) {

    const model: any = params.api.getModel();
    const rows = model ? model.rowsToDisplay : [];

    this.animals.markAsDirty();

    const animalsToSave = [];

    rows.forEach(node => {
      if (node.selected) {
        const find = this.animals.controls.find(animal => animal.value === node.data.id);
        if (!find) {
          animalsToSave.push(node.data.id);
          this.animals.push(this.fb.control(node.data.id));
        }
      } else {
        this.removeFromResultsIfNotSelected(node);
      }
    });

    if (animalsToSave.length) {
      this.addToAnimalEventResults(animalsToSave);
    }
  }

  removeFromResultsIfNotSelected(node: RowNode) {
    if (node.data.animalGroup) {
      const find = this.animalEventGroupResults.controls.find(animal => animal.get('animalId').value === node.data.id);
      if (find) {
        const model: any = this.gridApiGroup.getModel();
        const rows = model ? model.rowsToDisplay : [];
        const index = rows.findIndex(n => n.data.get('animalId').value === node.data.id);
        if (index >= 0) {
          this.appGridGroupChild.deleteRow(index);
        }
      }
    } else {
      const find = this.animalEventSingleResults.controls.find(animal => animal.get('animalId').value === node.data.id);
      if (find) {
        const model: any = this.gridApiSingle.getModel();
        const rows = model ? model.rowsToDisplay : [];
        const index = rows.findIndex(n => n.data.get('animalId').value === node.data.id);
        if (index >= 0) {
          this.appGridSingleChild.deleteRow(index);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('contextFormGroup')) {
      if (this.contextFormGroup && this.contextFormGroup !== this.boundContextFormGroup) {
        this.boundContextFormGroup = this.contextFormGroup;
        this.contextFormGroup.get('date').valueChanges.subscribe(() => setTimeout(() => this.onEventDateChanged(), 100));
        setTimeout(() => this.onEventDateChanged(), 100);
      }

      if (this.animal && this.animal.id) {
        this.setAnimalTrappedToQuarantine();
      } else if (this.contextFormGroup.get('stableId').value) {
        this.setStableTrappedToQuarantine();
      }
      this.drugs.valueChanges.subscribe(() => this.changeDrug());

      if (!this.contextFormGroup.get('id').value) {
        this.putAnimalsToResults();
      }

      if (!this.contextFormGroup.get('id').value) {
        this.changeResultLookupNameAndUpdateGrid();
      } else {
        this.changeResultItemCaption();
      }

      setTimeout(() => {
        if (this.appGridSingleChild) {
          this.appGridSingleChild.recalculateHeightGrid();
        }
        if (this.appGridGroupChild) {
          this.appGridGroupChild.recalculateHeightGrid();
        }
      }, 100);
      this.resultsCountsChanged.emit();
    }
  }

  setAnimalTrappedToQuarantine() {
    const animal = this.dataCachingService.getCachedData('EditAnimalCommon', this.animal.id.toString());
    const animalTrappedToQuarantine = this.dataCachingService.getCachedData('EditAnimalCommon_trappedToQuarantine',
      this.animal.id.toString());

    if (animal && animal.contextFormGroup) {
      this.setTrappedToQuarantine(animal.contextFormGroup.value.trappedToQuarantine);
    } else if (animalTrappedToQuarantine) {
      this.setTrappedToQuarantine(animalTrappedToQuarantine);
    } else {
      this.quarantineDataService.getTrappedAnimal(this.animal.id, this.contextFormGroup.get('date').value).subscribe({
        next: data => {
          this.setTrappedToQuarantine(data);
          this.dataCachingService.addToCache('EditAnimalCommon_trappedToQuarantine', this.animal.id.toString(), data);
        }
      });
    }
  }

  setStableTrappedToQuarantine() {
    const stable = this.dataCachingService.getCachedData('EditStableCommon', this.contextFormGroup.get('stableId').value.toString());
    const stableTrappedToQuarantine = this.dataCachingService.getCachedData('EditStableCommon_trappedToQuarantine',
      this.contextFormGroup.get('stableId').value.toString());

    if (stable && stable.contextFormGroup) {
      this.setTrappedToQuarantine(stable.contextFormGroup.value.trappedToQuarantine);
    } else if (stableTrappedToQuarantine) {
      this.setTrappedToQuarantine(stableTrappedToQuarantine);
    } else {
      this.quarantineDataService.getTrappedStable(this.contextFormGroup.get('stableId').value, this.contextFormGroup.get('date').value)
        .subscribe({
          next: data => {
            this.setTrappedToQuarantine(data);
          }
        });
    }
  }

  putAnimalsToResults() {
    if (!this.animals.length) {
      if (!this.animal) {
        return;
      }
      this.animals.push(this.fb.control(this.animal.id));

      if (this.animal.animalGroup) {
        if (!this.hasAnimalInResults(this.animal.id)) {
          this.addToAnimalEventGroupResults(this.animal);
        }
      } else {
        if (!this.hasAnimalInResults(this.animal.id)) {
          this.animalEventSingleResults.push(
            EditAnimalEventCommonFormComponent.getAnimalEventSingleResultsDef(this.fb,
              {animalId: this.animal.id, caption: this.animal.caption, result: null, state: null, reasonIsolated: null}
            ));
        }
      }
    } else {
      if (this.animal) {
        const find = this.contextFormGroup.get('animals').value
          .find(result => result === this.animal.id);
        if (!find) {
          this.animals.push(this.fb.control(this.animal.id));
        }
      }

      this.contextFormGroup.get('animals').value.forEach(animal => {
        if (!this.hasAnimalInResults(animal)) {
          this.addToAnimalEventResults([animal]);
        }
      });
    }
  }

  hasAnimalInResults(animalId: number) {
    const animalEventSingleResult = this.contextFormGroup.get('animalEventSingleResults').value
      .find(result => result.animalId === animalId);
    const animalEventGroupResult = this.contextFormGroup.get('animalEventGroupResults').value
      .find(result => result.animalId === animalId);
    return !!(animalEventSingleResult || animalEventGroupResult);
  }

  onEventDateChanged() {
    if (!this.animal || !this.animal.id || !this.contextFormGroup || !this.contextFormGroup.get('date').value ||
      this.lastLoadedCountsDate === this.contextFormGroup.get('date').value) {
      return;
    }

    if (!this.contextFormGroup.get('date').valid) {
      this.counts.controls.forEach(ctrl => {
        ctrl.get('countBefore').setValue(undefined);
      });
      return;
    }

    this.lastLoadedCountsDate = this.contextFormGroup.get('date').value;

    this.animalDataService.getAnimalCountsOnDate(
      this.animal.id,
      DateHelper.addDays(new Date(this.contextFormGroup.get('date').value), this.contextFormGroup.get('id').value ? 0 : 1),
      this.contextFormGroup.get('id').value).subscribe(
      {
        next: countsArr => {

          this.counts.controls.forEach(ctrl => {
            ctrl.get('countBefore').setValue(undefined);
          });

          if (countsArr && countsArr.length) {
            countsArr.forEach(el => {
              let countFg = this.counts.controls.find(ctrl => ctrl.get('animalSubTypeId').value === el.animalSubTypeId);
              if (!countFg) {
                countFg = EditAnimalEventCommonFormComponent.buildAnimalEventCount(this.fb, {
                  animalTypeId: el.animalTypeId,
                  animalSubTypeId: el.animalSubTypeId,
                  gender: el.gender,
                });
                this.counts.push(countFg);
              }

              countFg.get('countBefore').setValue(el.count);
            });
          }
        }
      });
  }

  searchAgent() {
    this.appNavigationService.searchAgent(AgentSearchModalComponent).subscribe(val => {
      if (val) {
        this.contextFormGroup.get('otherAgentId').setValue(val);
        this.contextFormGroup.markAsDirty();
      }
    });
  }

  searchStable() {
    this.cacheService.getAgent(this.contextFormGroup.get('otherAgentId').value).subscribe(agent => {
      this.appNavigationService.searchStable(StableSearchModalComponent, {
        owner: agent.id ? agent.caption : '',
        agentId: agent.id
      })
        .subscribe(val => {
          if (val) {
            this.contextFormGroup.get('otherStableId').setValue(val);
            this.contextFormGroup.markAsDirty();
          }
        });
    });
  }

  searchAnimal() {
    this.cacheService.getAgent(this.contextFormGroup.get('otherAgentId').value).subscribe(agent => {
      this.appNavigationService.searchAnimal(AnimalSearchModalComponent)
        .subscribe(val => {
          if (val) {
            this.contextFormGroup.get('otherAnimalId').setValue(val.id);
            this.contextFormGroup.markAsDirty();
          }
        });
    });
  }

  setStableAnimalMode() {
    this.contextFormGroup.get('animalStableMode').setValue(true);
    this.globalWaitingOverlayService.StartWaiting();
    this.animalDataService.searchByStable(this.animal.stableId, false)
      .subscribe({
        next: data => {
          this.lookupSourceService.getLookupObj('animal-type').subscribe(animalTypeIdLookup  => {
            this.lookupSourceService.getLookupObj('animal-subtype').subscribe(animalSubtypeIdLookup  => {
              this.animal.searchResults = [];
              data.forEach(el => {
                if (el.animalGroup) {
                  return;
                }

                const animalSubtype = (animalSubtypeIdLookup[el.animalTypeId] || []).find(x => x.id === el.animalSubtypeId);
                el.animalTypeIdCaption = animalTypeIdLookup[el.animalTypeId];
                el.animalSubtypeIdCaption = animalSubtype ? animalSubtype.caption : '<<не указан>>';

                this.animal.searchResults.push(el);
              });
              this.globalWaitingOverlayService.EndWaiting();
            });
          });
        }, error: () => {
          this.globalWaitingOverlayService.EndWaiting();
        }
      });
  }

  setTrappedToQuarantine(data: any[]) {
    if (!data) {
      return [];
    }
    this.trappedToQuarantine = [];
    const tmp = [];

    Object.keys(data).forEach(item => {
      this.lookupSourceService.getLookupCaption(item, 'quarantine')
        .subscribe(caption => {
          const existing = tmp.find(el => el.id === item);
          if (!existing) {
            tmp.push({
              id: item,
              caption: caption
            });
          } else {
            existing.caption = caption;
          }
          if (tmp.length === Object.keys(data).length) {
            this.trappedToQuarantine = tmp;
          }
        });
      });
  }

  addNewFile() {
    this.files.push(
      this.fb.group(EditAnimalEventCommonFormComponent.buildFormGroupFile(this.fb, this.model,
        {}, 'files-' + this.files.length)));
  }

  getFormGroup(obj: any) {
    return obj as AppFormGroup;
  }

  changeDrug() {
    this.availableDisease = null;
    this.availableEvents = null;

    this.drugs.getRawValue().forEach((drug, index) => {
      if (drug.drugTypeId) {
        this.lookupSourceService.getLookupObj('drug-type/' + drug.drugTypeId)
          .subscribe(el => {
            if (!this.availableDisease) {
              this.availableDisease = [];
            }
            if (!this.availableEvents) {
              this.availableEvents = [];
            }
            this.availableDisease.push(...el.diseaseTypeIds);
            this.availableEvents.push(...el.eventTypeIds);

            if (index === this.drugs.length - 2) {
              this.availableDisease = !this.availableDisease.length ? null : Array.from(new Set(this.availableDisease));
              this.availableEvents = !this.availableEvents.length ? null : Array.from(new Set(this.availableEvents));
            }
          });
      }
    });
  }

  changeAddAllAvailableDiseases() {
    while (this.contextFormGroup.get('diseases').value.length) {
      (this.contextFormGroup.get('diseases') as FormArray)
        .removeAt(this.contextFormGroup.get('diseases').value.length - 1);
    }
    this.contextFormGroup.get('diseaseTypeId').setValue(null);
    if (this.addAllAvailableDiseases) {
      this.availableDisease.forEach(diseaseId =>
        (this.contextFormGroup.get('diseases') as FormArray).push(this.fb.control(diseaseId)));
    }
  }

  changeOwnDrug(index: number, event: boolean) {
    this.drugs.at(index).get('drugId').setValue(null);
    this.drugs.at(index).get('drugTypeId').setValue(null);

    if (event && this.drugs.length === 1) {
      this.drugs.at(0).get('__own_money').setValue(true);
    }
  }

  getCaptionExamined(short = false) {
    const eventTypeId = this.contextFormGroup.get('id').value ? this.eventType.id : this.contextFormGroup.get('eventTypeId').value;
    const eventKind = this.contextFormGroup.get('id').value ? this.eventType.eventKind : this.contextFormGroup.get('eventKind').value;

    if (!eventTypeId || !eventKind) {
      return '';
    }
    return eventKind === 1
      ? eventTypeId === 32
        ? short ? 'Осм.' : 'Осмотрено'
        : short ? 'Иссл.' : 'Исследовано'
      : eventKind === 2
        ? short ? 'Пролеч.' : 'Пролечено'
        : eventTypeId === 13
          ? short ? 'Иммун.' : 'Иммунизировано'
          : short ? 'Обслуж.' : 'Обслужено';
  }

  getCaptionIsolated(short = false) {
    if (!this.contextFormGroup.get('eventTypeId').value) {
      return '';
    }
    return this.contextFormGroup.get('eventTypeId').value === 32
      ? short ? 'Запрет' : 'Запрещено на убой' : short ? 'Изолир' : 'Изолировано';
  }

  changeRangeTemperature() {
    this.contextFormGroup.get('rangeTemperature').setValue(!this.rangeTemperature);
    this.contextFormGroup.get('temperatureTo').setValue(null);
  }

  changeResultItemCaption() {
    this.lookupSourceService.getLookup(
      this.equalsSome(this.contextFormGroup.get('eventTypeId').value, 32, 36) ? 'exam-result/preslaughter' : 'exam-result', true, true)
      .subscribe(results => {
        const find = results.find(item => item.id === 4);
        if (find) {
          find.caption = this.contextFormGroup.get('eventTypeId').value === 13
            ? 'Иммунизировано'
            : 'Обработано';
        }
        this.availableResults = results;
      });
  }

  changeResultLookupNameAndUpdateGrid() {
    if (this.contextFormGroup.get('eventTypeId').value === 32 || this.contextFormGroup.get('eventTypeId').value === 36) {
      this.gridResultSingleColDefs[1].lookupName = 'exam-result/preslaughter';
    } else {
      this.gridResultSingleColDefs[1].lookupName = 'exam-result';
    }

    if (this.gridApiGroup) {
      setTimeout(() => {
        this.gridApiGroup.refreshHeader();
      }, 10);
      this.switchVisibalityReasonIsolated(this.gridApiGroup);
    } else {
      const groupColDef = this.gridResultGroupColDefs.find(def => def.field === 'reasonIsolated');
      groupColDef.hide = this.contextFormGroup.get('eventTypeId').value !== 32;
    }

    if (this.gridApiSingle) {
      this.switchVisibalityReasonIsolated(this.gridApiSingle);
      this.gridApiSingle.refreshView();
    } else {
      const singleColDef = this.gridResultSingleColDefs.find(def => def.field === 'reasonIsolated');
      singleColDef.hide = this.contextFormGroup.get('eventTypeId').value !== 32;
    }
  }

  private switchVisibalityReasonIsolated(gridApi: any) {
    const reasonIsolatedColumn = gridApi.columnController.columnApi.getAllColumns()
      .find(x => x.colDef.field === 'reasonIsolated');
    if (this.contextFormGroup.get('eventTypeId').value === 32) {
      gridApi.columnController.columnApi.setColumnVisible(reasonIsolatedColumn, true);
    } else {
      gridApi.columnController.columnApi.setColumnVisible(reasonIsolatedColumn, false);
    }
  }

  addToAnimalEventResults(animalIds: number[]) {
    this.globalWaitingOverlayService.StartWaiting();
    this.animalDataService.getAnimalCommonForLookup2(animalIds).subscribe(lookups => {
      lookups.forEach(lookup => {
        if (lookup.animalGroup) {
          this.addToAnimalEventGroupResults(lookup);
        } else {
          const group = EditAnimalEventCommonFormComponent.getAnimalEventSingleResultsDef(this.fb,
            {animalId: lookup.id, caption: lookup.caption, result: null, state: null, reasonIsolated: null}
          );
          this.animalEventSingleResults.push(group);
          if (this.gridApiSingle) {
            this.gridApiSingle.updateRowData({add: [group]});
          }
          setTimeout(() => {
            if (this.appGridSingleChild && this.contextFormGroup.get('eventTypeId').value) {
              this.appGridSingleChild.recalculateHeightGrid();
            }
          }, 300);
          this.resultsCountsChanged.emit();
        }
      });
      this.globalWaitingOverlayService.EndWaiting();
    });
  }

  addToAnimalEventGroupResults(lookup: any) {
    this.animalDataService.getAnimalCountsOnDate(lookup.id, DateHelper.addDays(new Date(), 1), undefined)
      .subscribe(
        {
          next: countsArr => {
            countsArr.forEach(count => {
              this.lookupSourceService.getLookupObj('animal-subtype').subscribe(animalSubtypeIdLookup  => {
                const animalSubtype = (animalSubtypeIdLookup[count.animalTypeId] || []).find(x => x.id === count.animalSubTypeId);

                if (animalSubtype) {
                  const group = EditAnimalEventCommonFormComponent.getAnimalEventGroupResultsDef(this.fb, {
                    animalId: count.animalId,
                    animalTypeId: count.animalTypeId,
                    animalSubTypeId: count.animalSubTypeId,
                    caption: animalSubtype.caption + ' ' + count.count + ' шт. (' + lookup.caption + ')', countDiff: null,
                    countPositive: null, countNegative: null, countRespond: null, countExamined: null, countIsolated: null,
                    reasonIsolated: null, count: count.count
                  });
                  this.animalEventGroupResults.push(group);
                  if (this.gridApiGroup) {
                    this.gridApiGroup.updateRowData({add: [group]});
                  }
                  setTimeout(() => {
                    if (this.appGridGroupChild && this.contextFormGroup.get('eventTypeId').value) {
                      this.appGridGroupChild.recalculateHeightGrid();
                    }
                  }, 300);
                  this.resultsCountsChanged.emit();
                }
              });
            });
          }
        });
  }

  addManyAnimals() {
    this.appNavigationService.searchManyAnimals(AnimalSearchModalComponent, {}).subscribe(animals => {
      if (!animals || !animals.length) {
        return;
      }

      const animalsToSave = [];

      animals.forEach(el => {
        const find = this.animals.controls.find(animal => animal.value === el);
        if (!find) {
          animalsToSave.push(el);
          this.animals.push(this.fb.control(el));
          this.animals.markAsDirty();
        }
      });

      this.addToAnimalEventResults(animalsToSave);
    });
  }

  public getLabelWithCounts(isGroup: boolean) {
    if (isGroup) {
      let totalGroupCount = 0;
      this.animalEventGroupResults.controls.forEach(result => {
        totalGroupCount += result.get('count').value;
      });
      return 'Групповой учет (добавлено ' + totalGroupCount + ' жив. \\ ' +
        (this.animalEventGroupResults.controls.length === 1
          ? '1 группа): '
          : this.animalEventGroupResults.controls.length + ' групп): ');
    } else {
      return 'Индивидуальный учет (добавлено ' + this.animalEventSingleResults.controls.length + ' жив.): ';
    }
  }

  deletingRow(data: any) {
    const index = this.animals.controls.findIndex(animal => animal.value === data.get('animalId').value);
    if (index >= 0) {
      this.animals.removeAt(index);
    }

    if (this.gridApiStableAnimals) {
      const selectedNodes = this.gridApiStableAnimals.getSelectedNodes();
      const find = selectedNodes.find(node => node.data.id === data.get('animalId').value);
      if (find) {
        find.setSelected(false);
      }
    }

    this.resultsCountsChanged.emit();
  }

  clearResultsTable(isGroup: boolean) {
    if (isGroup) {
      while (this.animalEventGroupResults.length !== 0) {
        const control = this.animalEventGroupResults.at(0);
        this.deletingRow(control);
        this.animalEventGroupResults.removeAt(0);
      }
    } else {
      while (this.animalEventSingleResults.length !== 0) {
        const control = this.animalEventSingleResults.at(0);
        this.deletingRow(control);
        this.animalEventSingleResults.removeAt(0);
      }
    }
    this.resultsCountsChanged.emit();
  }

  changeIsForced() {
    this.cdr.detach();
    this.contextFormGroup.get('isForced').setValue(!this.contextFormGroup.get('isForced').value);
    this.cdr.detectChanges();
    this.cdr.reattach();
  }
}
