import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { DateHelper } from '../../../helpers/date-helper';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';
import { StableSearchModalComponent } from '../../edit-stable/search/stable-search-modal.component';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { combineLatest } from 'rxjs';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { EditAnimalEventCommonFormComponent } from '../events/edit-animal-event-common-form.component';

@Component({
  selector: 'app-edit-animal-common-form',
  templateUrl: './edit-animal-common-form.component.html'
})
export class EditAnimalCommonFormComponent implements OnChanges {

  @Input() contextFormGroup: AppFormGroup;
  @Output() onNavigateEventsClicked: EventEmitter<any> = new EventEmitter<any>();

  isInvalid = FormHelper.isInvalid;
  placeholderPinTemplate = undefined;
  processMoneyKeypress = FormHelper.processMoneyKeypressDot;

  public get isExternalAnimal(): boolean {
    return !this.contextFormGroup.get('id').value && !!this.contextFormGroup.get('tempId').value;
  }

  public static createFormGroup(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any, isClone = false) {
    const group = fb.group({
      __stub__history: false,
      __stub__stable_history: false,
      id: val.id,
      tempId: val.tempId,
      createdTime: val.createdTime,
      createdUser: val.createdUser,
      deletedTime: val.deletedTime,
      deletedUser: val.deletedUser,
      idNumber: val.idNumber,
      animalGroup: val.animalGroup,
      animalTypeId: [val.animalTypeId, Validators.compose([Validators.required,
        serverSideErrorsValidator('animalTypeId', serverSideErrorsProvider)])],
      animalSubTypeId: [val.animalSubTypeId, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent)),
        serverSideErrorsValidator('animalSubTypeId', serverSideErrorsProvider),
      ])],
      nomenclatureAnimalPinId: val.nomenclatureAnimalPinId,
      pin: [val.pin, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent)),
        serverSideErrorsValidator('pin', serverSideErrorsProvider)])],
      caption: [val.caption, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => !EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent)),
        serverSideErrorsValidator('caption', serverSideErrorsProvider)])],
      birthDate: [val.birthDatePrettify, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, (ctrl => ctrl.parent &&
          (EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent) ||
          (!EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent) && +ctrl.parent.get('birthDateKind').value === 1)))),
        FormHelper.validateDateTimePicker(),
        serverSideErrorsValidator('birthDate', serverSideErrorsProvider),
      ])],
      birthDateKind: val.birthDateKind || 1,
      birthDateYear: [val.birthDateYear, Validators.compose([
        FormHelper.conditionalValidate(Validators.required,
            ctrl => ctrl.parent &&
              !EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent) &&
              +ctrl.parent.get('birthDateKind').value === 3),
        Validators.min(1917), Validators.max(2100)])],
      birthDateMonth: [val.birthDateMonth, Validators.compose([
        FormHelper.conditionalValidate(Validators.required,
          ctrl => ctrl.parent &&
            !EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent) &&
            +ctrl.parent.get('birthDateKind').value === 3),
        Validators.min(1), Validators.max(12)])],
      birthDateDay: [val.birthDateDay, Validators.compose([Validators.min(1), Validators.max(31)])],
      birthDate2Year: [val.birthDate2Year, Validators.compose([
        FormHelper.conditionalValidate(Validators.required,
          ctrl => ctrl.parent &&
            !EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent) &&
            +ctrl.parent.get('birthDateKind').value === 3),
        Validators.min(1917), Validators.max(2100)])],
      birthDate2Month: [val.birthDate2Month, Validators.compose([
        FormHelper.conditionalValidate(Validators.required,
          ctrl => ctrl.parent &&
            !EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent) &&
            +ctrl.parent.get('birthDateKind').value === 3),
        Validators.min(1), Validators.max(12)])],
      birthDate2Day: [val.birthDate2Day, Validators.compose([Validators.min(1), Validators.max(31)])],
      deathDate: [val.deathDate, Validators.compose([
        FormHelper.validateDateTimePicker(),
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && ctrl.parent.get('deathReasonId').value),
        serverSideErrorsValidator('deathDate', serverSideErrorsProvider),
      ])],
      deathReasonId: [val.deathReasonId, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && ctrl.parent.get('deathDate').value),
        serverSideErrorsValidator('deathReasonId', serverSideErrorsProvider),
      ])],
      identityDocSerial: val.identityDocSerial,
      identityDocNumber: val.identityDocNumber,
      identityDocDate: [val.identityDocDate, Validators.compose([
        FormHelper.validateDateTimePicker(),
        serverSideErrorsValidator('identityDocDate', serverSideErrorsProvider),
      ])],
      identityDocIssuer: val.identityDocIssuer,
      color: val.color,
      animalBreedId: val.animalBreedId,
      gender: [val.gender, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => EditAnimalCommonFormComponent.isSingleAnimal(ctrl.parent)),
        serverSideErrorsValidator('gender', serverSideErrorsProvider),
      ])],
      originCountry: val.originCountry,
      totalCount: val.totalCount,
      animalReasonId: val.animalReasonId,
      animalReasonText: val.animalReasonText,
      currentAgentId: val.currentAgentId,
      currentStableId: val.currentStableId,
      weight: [val.weight, FormHelper.getApplicationMoneyValidatorDot()],
      height: [val.height, FormHelper.getApplicationMoneyValidatorDot()],
      trappedToQuarantine: val.trappedToQuarantine,

      mercuryUuid: isClone ? undefined : val.mercuryUuid,
      mercuryJournalNo: isClone ? undefined : val.mercuryJournalNo,
      animalSubtypesWithProduct: fb.array(val.animalSubtypesWithProduct || []),

      counts: EditAnimalEventCommonFormComponent.buildAnimalEventCountsArray(fb, serverSideErrorsProvider,
        [], () => group, true, val.animalGroup.value),
    });

    let lastAnimalTypeId = val.animalTypeId;
    group.get('animalTypeId').valueChanges.subscribe(() => {
      if (lastAnimalTypeId !== group.get('animalTypeId').value) {
        group.get('animalSubTypeId').setValue(undefined);
        group.get('animalBreedId').setValue(undefined);
        lastAnimalTypeId = group.get('animalTypeId').value;
      }
    });

    return group;
  }

  public static isRequiredDateField(formGroup: FormGroup): boolean {
    return !formGroup.get('animalGroup').value;
  }

  private static isSingleAnimal(parent: FormGroup | FormArray) {
    return parent && !parent.get('animalGroup').value;
  }

  get animalSubTypeId() {
    return this.contextFormGroup.get('animalSubTypeId').value;
  }

  constructor(private fb: FormBuilder,
              private animalDataService: AnimalDataService,
              private navigationService: AppNavigationService,
              private cacheService: AddressPersonFioCacheService,
              public appNavigationService: AppNavigationService,
              private lookupService: LookupSourceService) {

  }

  ngOnChanges() {
    if (this.contextFormGroup) {
      this.contextFormGroup.get('animalSubTypeId').valueChanges.subscribe(() => this.searchPinTemplate());
    }
  }

  public isSingleAnimal() {
    return EditAnimalCommonFormComponent.isSingleAnimal(this.contextFormGroup);
  }


  public addAnimalAgent() {
    this.navigationService.performProductCreation(undefined, undefined,
      this.contextFormGroup.get('id').value, 1 /* Перевозка со сменой владельца */);
  }

  public addAnimalStable() {
    this.navigationService.performProductCreation(undefined, undefined,
      this.contextFormGroup.get('id').value, 1 /* Перевозка со сменой владельца */);
  }

  public get newCardCounts() {
    return this.contextFormGroup.get('counts') as FormArray;
  }

  public get counts() {
    if (!this.contextFormGroup || !this.contextFormGroup.get('id').value) {
      return [];
    }

    if (!(this.contextFormGroup as any).countsArr) {
      (this.contextFormGroup as any).countsArr = [];
      this.refreshAnimalCounts();
    }

    return (this.contextFormGroup as any).countsArr;
  }

  refreshAnimalCounts() {
    if (!this.contextFormGroup || !this.contextFormGroup.get('id').value) {
      return;
    }

    const animalId = this.contextFormGroup.get('id').value;

    this.animalDataService.getAnimalCountsOnDate(animalId, DateHelper.addDays(new Date(), 1), undefined)
      .subscribe(
        {
          next: countsArr => {
            (this.contextFormGroup as any).countsArr = countsArr;
          }
        });
  }

  navigateToEventsPage() {
    if (this.onNavigateEventsClicked) {
      this.onNavigateEventsClicked.emit();
    }
  }

  changeAnimalAgentOrStableNoVsd() {
    this.navigationService.performAnimalEventCreation(this.contextFormGroup.get('id').value, 29);
  }

  searchAgent() {
    this.navigationService.searchAgent(AgentSearchModalComponent, {
      stableId: this.contextFormGroup.get('currentStableId').value,
    }).subscribe(val => {
      if (val) {
        this.contextFormGroup.get('currentAgentId').setValue(val);
        this.contextFormGroup.markAsDirty();
      }
    });
  }

  searchStable() {
    this.cacheService.getAgent(this.contextFormGroup.get('currentAgentId').value).subscribe(agent => {
      this.navigationService.searchStable(StableSearchModalComponent, {
        owner: agent.id ? agent.caption : '',
        agentId: agent.id
      })
        .subscribe(val => {
          if (val) {
            this.contextFormGroup.get('currentStableId').setValue(val);
            this.contextFormGroup.markAsDirty();
          }
        });
    });
  }

  searchPinTemplate() {
    if (!this.animalSubTypeId) {
      this.placeholderPinTemplate = undefined;
      return;
    }

    combineLatest([
      this.lookupService.getLookupObj('nomenclature-animal-pin'),
      this.lookupService.getLookupObj('animal-subtype' + this.contextFormGroup.get('animalTypeId').value)
    ]).subscribe(([nomenclatureAnimalPinLookup, animalSubtypeLookup]) => {

      const animalSubtype = animalSubtypeLookup['Obj' + this.animalSubTypeId];

      if (!animalSubtype || !animalSubtype.nomenclatureAnimalPinId) {
        this.placeholderPinTemplate = undefined;
        return;
      }

      const nm = nomenclatureAnimalPinLookup['Obj' + animalSubtype.nomenclatureAnimalPinId];
      this.placeholderPinTemplate = nm ? nm.pinTemplate : undefined;
    });
  }

  getCaptionAge() {
    if (!this.contextFormGroup.get('birthDate').value) {
      return '';
    }

    const age = DateHelper.durationYearMonthDay(new Date(this.contextFormGroup.get('birthDate').value),
      this.contextFormGroup.get('deathDate').value ? this.contextFormGroup.get('deathDate').value : new Date()
        , true);

    return age ? 'Возраст: ' + age : '';
  }

  isRequiredDateField(formGroup: FormGroup): boolean {
    return EditAnimalCommonFormComponent.isRequiredDateField(formGroup);
  }

  getCertTitle(productInfo: any): string {
    return productInfo.transactionType === 4
      ? 'Производственный сертификат: ' + productInfo.animalSubtypeCaption
      : 'Транспортный сертификат: ' + productInfo.animalSubtypeCaption;
  }
}
