import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Required } from '../infrastructure/app-decotators';
import { AgGridLocalization } from './ag-grid-localization';
import { AgGridLookupRendererComponent } from './ag-grid-lookup-renderer.component';
import { AgGridButtonRendererComponent } from './ag-grid-button-renderer.component';
import { AgGridTextLookupEditorComponent } from './ag-grid-text-lookup-editor.component';
import { AgGridSearchModalEditorComponent } from './ag-grid-search-modal-editor.component';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AgGridTextInputEditorComponent } from './ag-grid-text-input-editor.component';
import { GridApi } from '@ag-grid-community/core/dist/es6/gridApi';
import { ColumnApi } from '@ag-grid-community/core/dist/es6/columnController/columnApi';
import { RowNode } from '@ag-grid-community/core/dist/es6/entities/rowNode';
import { FillOperationParams } from '@ag-grid-community/core/dist/es6/entities/gridOptions';
import { IsColumnFunc } from '@ag-grid-community/core/dist/es6/entities/colDef';
import { AgGridButtonStatusBarComponent } from './ag-grid-button-status-bar.component';
import { AgGridFormMasterDetailComponent } from './ag-grid-form-master-detail.component';
import { FormHelper } from './form-helper';

@Component({
  selector: 'app-grid-editable',
  template: `
      <ag-grid-angular #agGrid class="app-grid-editable ag-theme-balham" [style.width.px]="widthGrid" [style.height.px]="gridHeight"
                       [rowHeight]="defaultRowHeight" [frameworkComponents]="frameworkComponents"
                       [enableFillHandle]="true" [enableRangeSelection]="true" [fillOperation]="fillOperation"
                       [rowData]="formArray.controls" [columnDefs]="colDefs" [localeTextFunc]="agGridLocaleTextFunc"
                       [defaultColDef]="defColDef" (gridReady)="onGridReady($event)" [statusBar]="statusBar"
                       [suppressRowTransform]="true" [overlayNoRowsTemplate]="'Нет данных для отображения'"
                       [masterDetail]="true" [detailCellRenderer]="'formMasterDetailComponent'" [detailRowHeight]="detailRowHeight"
                       [detailCellRendererParams]="{}" [singleClickEdit]="singleClickEdit" (cellKeyDown)="onCellKeyDown($event)"
                       [suppressPropertyNamesCheck]="true" [getContextMenuItems]="getContextMenuItems"
                       [processCellForClipboard]="processCellForClipboard" [processCellFromClipboard]="processCellFromClipboard">
      </ag-grid-angular>`
})
export class AppGridEditableComponent {

  public static EDITOR_TYPE_MODAL_SEARCH = 1;
  public static EDITOR_TYPE_TEXT_LOOKUP = 2;
  public static EDITOR_TYPE_TEXT_INPUT = 3;
  public static BUTTON_TYPE = 4;
  public static HIDDEN_FIELD = 5;
  public static DATE_COMBO = 6;
  public static DATE_PICKER = 7;
  public static CHECKBOX = 8;
  public static COMPONENT = 9;
  public static APP_COMBO_LOOKUP = 10;
  public static TEXT_AREA = 11;

  gridHeight;
  @Input() defaultGridHeight = 62;
  defaultRowHeight = 32;

  frameworkComponents: any = {
    lookupRenderer: AgGridLookupRendererComponent,
    buttonRenderer: AgGridButtonRendererComponent,
    textLookupEditor: AgGridTextLookupEditorComponent,
    searchModalEditor: AgGridSearchModalEditorComponent,
    textInputEditor: AgGridTextInputEditorComponent,
    statusBarComponent: AgGridButtonStatusBarComponent,
    formMasterDetailComponent: AgGridFormMasterDetailComponent,
  };
  agGridLocaleTextFunc = AgGridLocalization.getLocalization;

  api: GridApi;
  columnApi: ColumnApi;
  colDefs = [];

  defColDef = {
    resizable: true,
    autoHeight: true,
    suppressKeyboardEvent: this.suppressKeys,
  };

  @Output() gridReady = new EventEmitter();
  @Output() cellValueChanged = new EventEmitter();
  @Output() deletingRow = new EventEmitter<any>();

  @Input() @Required columnDefs: any[];
  @Input() @Required formArray: FormArray;
  @Input() widthGrid = 700;
  @Input() maxHeight;
  @Input() statusBar: any;
  @Input() masterDetail = false;
  @Input() detailRowHeight = 450;
  @Input() detailCellRenderer: string;
  @Input() forceUpdateData = new EventEmitter();
  @Input() singleClickEdit = true;
  @Input() withEmptyRow = true;
  @Input() withAdd = true;
  @Input() withCopy = true;
  @Input() withDelete = true;
  @Input() recalculateHeight = true;
  @Input() validityControlsEvent = new EventEmitter();
  @Input() masterDetailCalcHeightFunc: (rowNode: RowNode) => number;
  @Input() masterDetailRowAvailableFunc: (rowNode: RowNode) => boolean = (rowNode) => true;

  /* tslint:disable */
  public static clearSelectedCells(params: any) {
    const cellRanges = params.api.getCellRanges();

    cellRanges.forEach(cells => {
      const colIds = cells.columns.map(col => col.colId);

      const startRowIndex = Math.min(
        cells.startRow.rowIndex,
        cells.endRow.rowIndex
      );

      const endRowIndex = Math.max(
        cells.startRow.rowIndex,
        cells.endRow.rowIndex
      );
      AppGridEditableComponent.clearCells(params, startRowIndex, endRowIndex, colIds);
    });
  }

  private static clearCells(params, start, end, columns) {
    for (let i = start; i <= end; i++) {
      const rowNode = params.api.getRowNode(i);

      columns.forEach(column => {
        if (rowNode.data && (rowNode.data as FormGroup).contains(column)) {
          (rowNode.data as FormGroup).controls[column].setValue('');
        }
      });
      params.api.redrawRows({rowNodes: [rowNode]});
    }
  }
  /* tslint:enable */

  constructor (private fb: FormBuilder) {
  }

  onGridReady(params: any) {
    this.api = params.api;
    this.columnApi = params.columnApi;

    if (!this.columnDefs || !this.columnDefs.length) {
      return;
    }

    this.colDefs = this.columnDefs.slice();
    this.addButtonEditRow(this.withAdd, this.withCopy, this.withDelete);
    this.columnSettings();

    if (!this.formArray.controls.length) {
      this.addRow(this.fb.group({}));
    } else {
      this.setRowData();
    }

    this.forceUpdateData.subscribe(() => {
      this.setRowData();
      this.colDefs.forEach(def => {
        if (def.valueChangedCallback) {
          this.formArray.controls.forEach(c => def.valueChangedCallback(c.get(def.field).value, c, null, this.api));
        }
      });
      this.api.getRenderedNodes().forEach(node => node.expanded = false);
      this.api.refreshCells({force: true});
    });

    this.validityControlsEvent.subscribe(() => this.validityControls());
    this.gridReady.emit(params);
  }

  // добавляем слева кнопки (добавить, копировать, удалить строки)
  private addButtonEditRow(withAdd: boolean, withCopy: boolean, withDelete: boolean) {
    if (withAdd) {
      this.colDefs.unshift({
        width: 22,
        editorType: AppGridEditableComponent.BUTTON_TYPE,
        buttonRendererParams: row => {
          return {
            icon: 'plus',
            title: 'Добавить строку',
            onClickCallback: (params) => {
              const cloneData = FormHelper.cloneAbstractControl(params.data);
              FormHelper.resetAllFields(cloneData, this.columnDefs.filter(def => def.copyToNewRow).map(def => def.field));
              this.addRow(cloneData, true, row.rowIndex + 1);
            },
          };
        },
      });
    }
    if (withCopy) {
      this.colDefs.unshift({
        width: 22,
        editorType: AppGridEditableComponent.BUTTON_TYPE,
        buttonRendererParams: row => {
          return {
            icon: 'copy',
            title: 'Копировать строку',
            onClickCallback: (params) => this.addRow(params.data, true, this.formArray.length, true, false),
          };
        },
      });
    }
    if (withDelete) {
      this.colDefs.unshift({
        width: 22,
        editorType: AppGridEditableComponent.BUTTON_TYPE,
        buttonRendererParams: row => {
          return {
            icon: 'trash',
            title: 'Удалить строку',
            onClickCallback: () => this.deleteRow(row.rowIndex),
          };
        },
      });
    }
    if (this.masterDetail) {
      this.colDefs.push({
        width: 22,
        editorType: AppGridEditableComponent.BUTTON_TYPE,
        buttonRendererParams: row => {
          return {
            icon: row.node.expanded ? 'angle left' : 'angle left',
            title: 'Раскрыть строку',
            disabled: !this.masterDetailRowAvailableFunc(row),
            onClickCallback: (params) => {
              row.node.setExpanded(!row.node.expanded);
              params.icon = row.node.expanded ? 'angle down' : 'angle left';
              this.recalculateHeightGrid();
            },
          };
        },
      });
    }
  }

  // обрабатываем editor's в соответствии с настройками каждого столбца
  private columnSettings() {
    this.colDefs.forEach(def => {
      this.commonSettings(def);
      if (def.editorType === AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH) {
        this.modalSearchSettings(def);
      } else if (def.editorType === AppGridEditableComponent.EDITOR_TYPE_TEXT_LOOKUP) {
        this.lookupSettings(def);
      } else if (def.editorType === AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT) {
        this.textInputSettings(def);
      } else if (def.editorType === AppGridEditableComponent.BUTTON_TYPE) {
        this.buttonSettings(def);
      } else if (def.editorType === AppGridEditableComponent.HIDDEN_FIELD ||
                 def.editorType === AppGridEditableComponent.DATE_COMBO ||
                 def.editorType === AppGridEditableComponent.DATE_PICKER ||
                 def.editorType === AppGridEditableComponent.CHECKBOX ||
                 def.editorType === AppGridEditableComponent.COMPONENT ||
                 def.editorType === AppGridEditableComponent.APP_COMBO_LOOKUP ||
                 def.editorType === AppGridEditableComponent.TEXT_AREA) {
      } else {
        throw new Error(`Unknown attribute 'editorType'`);
      }
    });
  }

  private commonSettings(def: any) {
    if (def.required) {
      if (!def.cellClassRules) {
        def.cellClassRules = {};
      }
      def.cellClassRules['ag-cell-error-validation'] = params =>
        (def.required(params) && !params.data.get(def.field).value) || params.data.get(def.field).invalid;
    }
    if (def.isInnerForm) {
      def.hide = true;
    }
    if (def.recalculateDetailHeightAfterChange) {
      def.onCellValueChanged = () => {
        this.recalculateHeightGrid();
        setTimeout(() => {
          this.cellValueChanged.emit();
        }, 10);
      };
    }
  }

  private modalSearchSettings(def: any) {
    def.cellEditor = 'searchModalEditor';
    def.cellEditorParams = row => {
      return {
        controlName: def.field,
        value: row.data[def.field],
        lookupName: def.lookupName,
        searchParams: def.searchParams,
        manySelectedCallback: (params, values) => {
          values.forEach(v => {
            const cloneData = FormHelper.cloneAbstractControl(params.data);
            cloneData.get(def.field).setValue(v);
            if (params.colDef.valueChangedCallback) {
              params.colDef.valueChangedCallback(v, cloneData, params.node, this.api);
            }
            this.addRow(cloneData, false, null, false);
          });
          this.setRowData();
        },
        callbackAfterChangeValue: def.callbackAfterChangeValue,
      };
    };
    def.cellRenderer = 'lookupRenderer';
    def.cellRendererParams = row => {
      return {
        value: row.data.get([def.field]).value,
        lookupName: def.lookupName,
        isPipe: true,
      };
    };
  }

  private lookupSettings(def: any) {
    def.cellEditor = 'textLookupEditor';
    def.cellEditorParams = row => {
      return {
        value: row.data.get([def.field]).value,
        controlName: def.field,
        lookupName: def.lookupName,
        disabledChoices: def.disabledChoices,
        parentFieldLookup: def.parentFieldLookup,
      };
    };
    def.cellRenderer = 'lookupRenderer';
    def.cellRendererParams = row => {
      return {
        value: row.data.get([def.field]).value,
        value2: def.parentFieldLookup ? row.data.get([def.parentFieldLookup]).value : null,
        requiredValue2: def.requiredValue2,
        lookupName: def.lookupName,
        isPipe: def.isPipe,
      };
    };
  }

  private textInputSettings(def: any) {
    def.cellEditor = 'textInputEditor';
    def.cellEditorParams = row => {
      return {
        value: row.data.get([def.field]).value,
        controlName: def.field,
        keyPressCallback: def.keyPressCallback,
      };
    };
    def.valueFormatter = params => params.data.get([def.field]).value;
  }

  private buttonSettings(def: any) {
    def.cellRenderer = 'buttonRenderer';
    def.cellRendererParams = def.buttonRendererParams;
    def.editable = false;
    def.cellClass = 'app-grid-editable-button-cell';
  }

  addRow(data: FormGroup, updatedData = true, index?: number, isNeedCloned = true, calcDefValue = true) {
    const cloneData = isNeedCloned ? FormHelper.cloneAbstractControl(data) : data;

    if (calcDefValue) {
      this.columnDefs.forEach(colDef => {
        if ('defValueFunc' in colDef) {
          colDef.defValueFunc(cloneData);
        }
      });
    }

    if (index) {
      this.formArray.insert(index, cloneData);
    } else {
      this.formArray.push(cloneData);
    }
    if (updatedData && this.api) {
      this.setRowData();
    }
  }

  deleteRow(index: number) {
    const deleteData = this.formArray.at(index);
    this.columnDefs.forEach(colDef => {
      if (colDef.valueChangedCallback) {
        deleteData.get(colDef.field).setValue(null);
        colDef.valueChangedCallback(null, deleteData, null, this.api);
      }
    });
    if (this.formArray.length === 1 && this.withEmptyRow) {
      const cloneData = FormHelper.cloneAbstractControl(this.formArray.at(index));
      FormHelper.resetAllFields(cloneData, this.columnDefs.filter(def => def.copyToNewRow).map(def => def.field));
      this.formArray.removeAt(index);
      this.addRow(cloneData as FormGroup);
    } else {
      this.formArray.removeAt(index);
    }
    if (this.api) {
      this.setRowData();
    }
    this.deletingRow.emit(deleteData);
  }

  setRowData() {
    this.api.setRowData(this.formArray.controls);
    this.recalculateHeightGrid();
  }

  fillOperation(params: FillOperationParams) {
    if (params.direction === 'down' || params.direction === 'up') {
      const cellRanges = params.api.getCellRanges();
      if (!cellRanges || !cellRanges.length) {
        return;
      }

      const cell = cellRanges[0];
      const colDef = params.column.getColDef();
      const editNode = params.api.getDisplayedRowAtIndex(params.direction === 'down'
                                                                 ? cell.endRow.rowIndex + params.currentIndex + 1
                                                                 : cell.startRow.rowIndex - params.currentIndex - 1);

      const editable = colDef.editable
                         ? typeof colDef.editable === 'function'
                           ? (colDef.editable as IsColumnFunc).call(this, editNode)
                           : colDef.editable
                         : false;

      if (editable) {

        editNode.data.get(colDef.field).setValue(params.api.getDisplayedRowAtIndex(cell.startRow.rowIndex).data.get(colDef.field).value);
        params.api.redrawRows({rowNodes: [editNode]});
      }
      return editNode && editNode.data && editNode.data.contains(colDef.field) ? editNode.data.get(colDef.field).value : undefined;
    }
  }

  public recalculateHeightGrid() {
    if (!this.api) {
      return;
    }
    if (this.recalculateHeight) {
      let allDetailRowsHeight = 0;
      this.api.getRenderedNodes().forEach(node => {
        if (node.expanded && node.detailNode) {
          node.detailNode.setRowHeight(this.masterDetailCalcHeightFunc
            ? this.masterDetailCalcHeightFunc(node)
            : this.detailRowHeight);
          allDetailRowsHeight += node.detailNode.rowHeight;
          node.rowHeightEstimated = true;
        }
        if (!node.detail) {
          node.setRowHeight(this.defaultRowHeight);
        }
      });
      const val = this.formArray.length * this.defaultRowHeight + this.defaultGridHeight + allDetailRowsHeight;
      this.gridHeight = this.maxHeight && val > this.maxHeight
        ? this.maxHeight
        : val;
    } else {
      this.gridHeight = this.defaultGridHeight;
    }
    this.api.onRowHeightChanged();
  }

  validityControls() {
    this.columnDefs.forEach(colDef => {
      if (colDef.isInnerForm) {
        this.api.getRenderedNodes().forEach(node => {
          if ((node.data as FormGroup).contains(colDef.field) && (node.data as FormGroup).get(colDef.field).invalid) {
            node.setExpanded(true);
            this.recalculateHeightGrid();
            setTimeout(() => {
              const invalidControl = this.recursiveSearchInvalidControl((node.data as FormGroup).get(colDef.field));
              if (invalidControl) {
                (<any>invalidControl).nativeElement.focus();
              }
            }, 100);
          }
        });
      }
    });
  }

  recursiveSearchInvalidControl(fg: any): FormControl {
    if (!fg) {
      return null;
    }
    if (fg instanceof FormGroup) {
      let invalidControl = null;
      Object.keys(fg.value).forEach(k => {
        if (!invalidControl) {
          invalidControl = this.recursiveSearchInvalidControl(fg.get(k));
        }
      });
      return invalidControl;

    } else if (fg instanceof FormArray) {
      const invalided = (fg as FormArray).controls.filter(k => k.invalid);
      return invalided.length ? this.recursiveSearchInvalidControl(invalided[0]) : null;
    }

    if (!fg.invalid) {
      return fg;
    }

    return fg as FormControl;
  }

  getContextMenuItems(params: any) {
    return [
      'copy',
      'paste',
      {
        name: 'Очистить',
        shortcut: 'Delete',
        action: () => {
          if (params.api && params.node && params.node.data && params.column && params.column.colDef && params.column.colDef.field) {
            AppGridEditableComponent.clearSelectedCells(params);
          }
        },
        icon: '<clr-icon shape="trash" style="color:#7F8C8D;"></clr-icon>',
      },
    ];
  }

  processCellForClipboard(params: any) {
    return (params.node.data as FormGroup).controls[params.column.colDef.field].value;
  }

  processCellFromClipboard(params: any) {
    if (!params || !params.api || !params.node || !params.node.data || !params.column || !params.column.colDef) {
      return;
    }
    (params.node.data as FormGroup).controls[params.column.colDef.field].setValue(params.value);
    params.node.setDataValue(params.column, params.value);
    if (params.column.colDef.valueChangedCallback) {
      params.column.colDef.valueChangedCallback(params.value, params.node.data, params.node, params.api);
    }
    params.api.redrawRows({rowNodes: [params.node]});
  }

  onCellKeyDown(params: any) {
    const keyPress = params.event.key;
    if (keyPress && keyPress.toLowerCase() === 'delete') {
      params.api.stopEditing();
      AppGridEditableComponent.clearSelectedCells(params);
    }
  }

  suppressKeys(params: any) {
    return ['delete'].some(suppressedKey => {
      return params.event.key.toLowerCase() === suppressedKey;
    });
  }
}
