import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { EditAnimalComponent } from '../../edit-animal/edit-animal.component';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DateHelper } from '../../../helpers/date-helper';
var EditAgentAnimalsComponent = /** @class */ (function () {
    function EditAgentAnimalsComponent(animalDataService, dataCachingService, waitingOverlay, lookupSourceService, navigationService, alertService) {
        this.animalDataService = animalDataService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.lookupSourceService = lookupSourceService;
        this.navigationService = navigationService;
        this.alertService = alertService;
        this.model = {};
        this.isInvalid = FormHelper.isInvalid;
        this.gridColumnDefs = EditAgentAnimalsComponent.getGridColumnDefs();
    }
    Object.defineProperty(EditAgentAnimalsComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditAgentAnimalsComponent.getGridColumnDefs = function () {
        return [
            {
                headerName: '№', field: 'idNumber', width: 130,
                cellRenderer: function (params) {
                    return !params.data.tempId
                        ? (params.data.hasWaitingConfirmedChanges
                            ? "<clr-icon shape=\"hourglass\" style=\"color:#fb8c00\" title=\"\u041D\u0435\u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0451\u043D\u043D\u044B\u0435 \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F\"></clr-icon>&nbsp;"
                            : '') + params.value || params.data.id
                        : "<clr-icon shape=\"hourglass\" style=\"color:#fb8c00\" title=\"\u041D\u0435\u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0451\u043D\u043D\u0430\u044F \u043A\u0430\u0440\u0442\u043E\u0447\u043A\u0430\"></clr-icon>";
                },
                checkboxSelection: function (x) { return x.data.tempId; },
            },
            {
                headerName: 'Сертификат', field: 'hasStockEntryRecordTitle', width: 50,
                cellRenderer: function (params) {
                    return params && params.data && params.data.hasStockEntryRecord
                        ? "<clr-icon shape=\"certificate\" style=\"color:green\" title=\"\u0418\u043C\u0435\u0435\u0442\u0441\u044F \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442\"></clr-icon>"
                        : "";
                },
                filterValueGetter: function (params) { return params.data.hasStockEntryRecord ? 'есть сертификат' : 'нет сертификата'; },
                cellStyle: { 'padding-left': '17px' },
            },
            {
                headerName: 'Убой', field: 'examResultTitle', width: 50,
                cellRenderer: function (params) {
                    return params && params.data && params.data.examResult
                        ? [1].includes(+params.data.examResult)
                            ? "<clr-icon shape=\"no-access\" title=\"\u0417\u0430\u043F\u0440\u0435\u0449\u0435\u043D\u043E \u043D\u0430 \u0443\u0431\u043E\u0439\"></clr-icon>"
                            : "<clr-icon shape=\"success-standard\" style=\"color:green\" title=\"\u0420\u0430\u0437\u0440\u0435\u0448\u0435\u043D\u043E \u043D\u0430 \u0443\u0431\u043E\u0439\"></clr-icon>"
                        : "";
                },
                filterValueGetter: function (params) { return params.data.examResult === 1
                    ? 'Запрещено на убой'
                    : params.data.examResult === 2 ? 'Разрешено на убой' : ''; },
                cellStyle: { 'padding-left': '17px' },
            },
            {
                headerName: 'Наименование', field: 'caption', width: 250,
                cellRenderer: function (params) {
                    var animalStateHtml = params.data.overdueEventsCount && params.data.overdueEventsCount > 0
                        ? '<clr-icon shape="history" style="color:red"' +
                            ' title="Имеются просроченные мероприятия: ' + params.data.overdueEventsCount + '"></clr-icon>'
                        : '<clr-icon shape="check" style="color:green" title="Просроченные мероприятия отсутствуют"></clr-icon>';
                    return animalStateHtml + '&nbsp;' + (params.data.animalGroup ? '<clr-icon shape="layers"></clr-icon>&nbsp;' : '') + params.value;
                }
            },
            {
                headerName: 'Состояние животного',
                field: 'overdueEventsCount',
                hide: true,
                suppressColumnsToolPanel: true,
                suppressFiltersToolPanel: true,
            },
            {
                headerName: 'Кол-во', field: 'totalCount', width: 70,
            },
            {
                headerName: 'Вид', field: 'animalTypeIdCaption', width: 180,
            },
            {
                headerName: 'Подвид', field: 'animalSubtypeIdCaption', width: 150,
            },
            {
                headerName: 'Возраст', field: 'birthDate', width: 250,
                cellRenderer: function (params) {
                    if (!params.value) {
                        return '';
                    }
                    var age = DateHelper.durationYearMonthDay(new Date(params.value), params.data.deathDate || new Date(), true);
                    return age || '';
                }
            },
            {
                headerName: 'Местонахождение', field: 'stable', width: 200,
            },
            {
                headerName: 'Владение с', field: 'dateFrom', width: 120,
                valueFormatter: function (params) { return StringHelper.getRuDate(params.value); },
            },
            {
                headerName: 'Владение по', field: 'dateTo', width: 120,
                valueFormatter: function (params) { return StringHelper.getRuDate(params.value); },
            }
        ].concat(EditAnimalComponent.getGridGroupingColumns());
    };
    EditAgentAnimalsComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditAgentAnimals', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditAgentAnimalsComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditAgentAnimals', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.lookupSourceService.getLookupObj('animal-type').subscribe(function (animalTypeLookup) {
                _this.lookupSourceService.getLookupObj('animal-subtype').subscribe(function (animalSubtypeIdLookup) {
                    _this.animalDataService.searchByAgent(_this.id, _this.model.showHistory)
                        .subscribe({
                        next: function (data) {
                            data.forEach(function (el) {
                                var animalSubtype = (animalSubtypeIdLookup[el.animalTypeId] || []).find(function (x) { return x.id === el.animalSubtypeId; });
                                el.animalTypeIdCaption = el.animalTypeId ? animalTypeLookup[el.animalTypeId] : '<<Не указан>>';
                                el.animalSubtypeIdCaption = animalSubtype ? animalSubtype.caption : '<<не указан>>';
                                el.totalCount = el.totalCount || 1;
                            });
                            _this.model = { searchResults: data, showHistory: _this.model.showHistory };
                            _this.dataCachingService.addToCache('EditAgentAnimals', _this.id.toString(), _this.model);
                            _this.waitingOverlay.EndWaiting();
                        }, error: function () {
                            _this.waitingOverlay.EndWaiting();
                        }
                    });
                });
            });
        }
    };
    EditAgentAnimalsComponent.prototype.getRowStyle = function (params) {
        if (params.data && params.data.dateTo) {
            return { color: 'silver' };
        }
    };
    EditAgentAnimalsComponent.prototype.addNew = function () {
        this.navigationService.performAnimalCreation(this.id);
    };
    EditAgentAnimalsComponent.prototype.onGridSelectionChanged = function (params) {
        this.model.selectedRows = params.api.getSelectedRows();
    };
    EditAgentAnimalsComponent.prototype.confirmSelected = function () {
        var _this = this;
        this.alertService.confirmModal('Подтвердить выделенных животных?')
            .subscribe(function (val) {
            if (val) {
                _this.animalDataService.confirmManyAnimalExternal(_this.model.selectedRows).subscribe(function () { return _this.refreshFormData(); });
            }
        });
    };
    return EditAgentAnimalsComponent;
}());
export { EditAgentAnimalsComponent };
