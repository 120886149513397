import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { ProductSearchResultsFormComponent } from '../../edit-product/search/product-search-results-form.component';
import {
  AgGridProductTransactionDetailRendererComponent
} from '../../../ui/controls/ag-grid-product-transaction-detail-renderer.component';
import { ProductSearchResultsComponent } from '../../edit-product/search/product-search-results.component';
import { MetadataService } from '../../../logic/services/metadata.service';
import { environment } from '../../../../environments/environment';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { SecurityService } from '../../../logic/services/security.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-edit-stable-products',
  templateUrl: './edit-stable-products.component.html'
})
export class EditStableProductsComponent implements OnInit {

  @Input() model: any = {};

  isInvalid = FormHelper.isInvalid;
  getRowStyle = ProductSearchResultsFormComponent.getRowStyle;
  gridColumnDefs = ProductSearchResultsFormComponent.getGridColumnDefs();
  equalsSome = FormHelper.equalsSome;

  stableTypeIdLookup: any = {};

  detailCellRenderer = 'myDetailCellRenderer';
  frameworkComponents = { myDetailCellRenderer: AgGridProductTransactionDetailRendererComponent };
  gridIcons = {
    groupContracted: '<clr-icon shape="organization" style="cursor: pointer"></clr-icon>',
  };

  syncState;
  syncStateResult = {};
  updateSyncStateTimer: any;
  updateSyncStatePending = false;
  syncStateForUpdate: FormGroup;

  productDates: any[] = [];

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  constructor(private productDataService: ProductDataService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private lookupSourceService: LookupSourceService,
              public navigationService: AppNavigationService,
              private alertService: AlertService,
              private metadataService: MetadataService,
              private personCacheService: AddressPersonFioCacheService,
              public securityService: SecurityService,
              private fb: FormBuilder,
              private stableDataService: StableDataService) {

    this.lookupSourceService.getLookupObj('stable-type').subscribe(lookup => this.stableTypeIdLookup = lookup);
    this.model.currentDate = new Date();
  }

  public buildSyncState(item: any) {
    this.syncStateForUpdate = this.fb.group({
      targetObjId: item.targetObjId,
      conversationTypeId: item.conversationTypeId,
      lastAppliedTimeTo: item.lastAppliedTimeTo,
    });
  }

  ngOnInit(): void {
    this.updateSyncStateTimer = setInterval(() => this.refreshSyncStateInternal(true), 10000);
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditStableProducts', this.id.toString());
    this.dataCachingService.removeCachedData('EditStableProductsSyncState', this.id.toString());
    this.dataCachingService.removeCachedData('EditStableProductsTemplates', this.id.toString());
    this.refreshFormDataInternal();
  }

  getPreviousMonth() {
    if (this.model.currentDate.getMonth() === 0) {
      this.model.currentDate.setMonth(11);
      this.model.currentDate.setFullYear(this.model.currentDate.getFullYear() - 1);
    } else {
      this.model.currentDate.setMonth(this.model.currentDate.getMonth() - 1);
    }
    this.refreshFormData();
  }

  getNextMonth() {
    if (this.model.currentDate.getMonth() === 11) {
      this.model.currentDate.setMonth(0);
      this.model.currentDate.setFullYear(this.model.currentDate.getFullYear() + 1);
    } else {
      this.model.currentDate.setMonth(this.model.currentDate.getMonth() + 1);
    }
    this.refreshFormData();
  }

  loadDates() {

    let filterSet = new Set();
    let sortedDates = [];
    this.productDataService.getDateAndTimeByStableIdDistinct(this.id).forEach(
      el => {
        el.forEach(a => filterSet.add(a.slice(0, -3)));
        filterSet.forEach( a => sortedDates.push(a));
        sortedDates.sort();
        sortedDates.forEach(a => this.productDates.push(new Date(a)));
      }
    );
  }

  changeDate(date: any) {
    this.model.currentDate.setFullYear(date.getFullYear());
    this.model.currentDate.setMonth(date.getMonth());
    this.refreshFormData();
  }

  refreshFormDataInternal(isBackground = false) {
    if (!isBackground) {
      this.waitingOverlay.StartWaiting();
    }
    if (this.productDates.length === 0) {
      this.loadDates();
    }

    const existing = this.dataCachingService.getCachedData('EditStableProducts', this.id.toString());

    if (existing) {
      this.model = existing;
      if (!isBackground) {
        this.waitingOverlay.EndWaiting();
      }
    } else {
      this.productDataService.getStableProducts(this.id, this.getDateFrom(), this.getDateTo())
        .subscribe({
          next: data => {

            combineLatest([
              this.lookupSourceService.getLookupObj('product-subtype'),
              this.lookupSourceService.getLookupObj('product-status'),
              this.lookupSourceService.getLookupObj('transaction-type'),
            ])
            .subscribe(([productSubtypeLookup, productStatusLookup, transactionTypeLookup]) => {
              data.forEach(el => {
                el.transactionTypeCaption = transactionTypeLookup[el.transactionType];
                el.statusCaption = productStatusLookup[el.status];
                if (el.status === -100 && el.templateCaption) {
                  el.statusCaption = 'Шаблон: ' + el.templateCaption;
                }

                if (el.stableProductSubtypeCaption) {
                  el.productSubtypeIdCaption = el.stableProductSubtypeCaption;
                } else {
                  const productSubtypeRec = productSubtypeLookup[el.productTypeId].find(ptst => ptst.id === el.productSubtypeId);
                  el.productSubtypeIdCaption = productSubtypeRec ? productSubtypeRec.caption : undefined;
                }
              });

              data = data.filter(el => !(this.model.selectedRows || []).find(sr => sr.id === el.id));
              data.sort((a, b) => b.id - a.id);

              (this.model.selectedRows || []).forEach(selectedRow => data.splice(0, 0, selectedRow));

              this.model = {
                searchResults: data,
                currentDate: this.model.currentDate,
                selectedRows: this.model.selectedRows || [],
                selectedRowsIds: this.model.selectedRowsIds || [],
              };
              this.dataCachingService.addToCache('EditStableProducts', this.id.toString(), this.model);
              this.refreshTemplatesInternal();
              this.refreshSyncState();
              if (!isBackground) {
                this.waitingOverlay.EndWaiting();
              }
            });
          },
          error: () => {
            if (!isBackground) {
              this.waitingOverlay.EndWaiting();
            }
          }
        });
    }

    this.syncState = undefined;
    const existingSyncState = this.dataCachingService.getCachedData('EditStableProductsSyncState', this.id.toString());
    if (existingSyncState) {
      this.updateSyncState(existingSyncState);
    } else {
      this.refreshSyncStateInternal();
    }
  }

  refreshTemplatesInternal() {
    const existing = this.dataCachingService.getCachedData('EditStableProductsTemplates', this.id.toString());

    if (existing) {
      this.model.templates = existing;
    } else {
      this.productDataService.getStableProductsTemplates(this.id)
        .subscribe({
          next: data => {
            this.model.templates = data;
            this.dataCachingService.addToCache('EditStableProductsTemplates', this.id.toString(), this.model.templates);
          }
        });
    }
  }

  getDateFrom(): Date {
    return new Date(this.model.currentDate.getFullYear(), this.model.currentDate.getMonth(), 1);
  }

  getDateTo(): Date {
    return new Date(this.model.currentDate.getFullYear(), this.model.currentDate.getMonth() + 1, 0);
  }

  addNew() {
    this.navigationService.performProductCreation(null, null,
      null, null, this.id);
  }

  updateFromMercuryAndRefreshFormData() {
    this.stableDataService.startSyncIncomingProducts(this.id).subscribe(() => this.refreshSyncState());
  }

  onGridSelectionChanged(params: any) {
    this.model.selectedRows = params.api.getSelectedRows();
    this.model.selectedRows.sort((a, b) => a.id - b.id);
    this.model.selectedRowsIds = this.model.selectedRows.map(x => x.id);
  }

  deleteDocument() {
    ProductSearchResultsComponent.deleteManyDocuments(this.model.selectedRows, this.alertService, this.productDataService,
      () => this.refreshFormData());
  }

  sendToRegistration() {
    ProductSearchResultsComponent.sendManyDocumentsToRegistration(this.model.selectedRows, this.alertService, this.productDataService,
      () => this.refreshFormData());
  }

  sendToClearance() {
    ProductSearchResultsComponent.sendManyDocumentsToClearance(this.model.selectedRows, this.alertService, this.productDataService,
      () => this.refreshFormData());
  }

  sendToAcceptManyProductRequests() {
    this.productDataService.startSyncDecisionMakingProductRequest({
      productRequestIds: this.model.selectedRows.map(x => x.id),
      newProductRequestStatus: 2,
    }).subscribe(() => this.refreshFormData());
  }

  createTransportForSelectedDocuments() {
    this.navigationService.performProductCreation(undefined, undefined, undefined,
      undefined, this.id, this.model.selectedRows.map(x => x.id));
  }

  downloadLog(filename: string) {
    this.metadataService.preloadFile(filename).subscribe(fileHash => {
      window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
    });
  }

  private refreshSyncState() {
    this.syncState = undefined;
    this.refreshSyncStateInternal();
  }

  private refreshSyncStateInternal(isRetry = false) {

    if (!this.model) {
      return;
    }

    // если ничего не запланировано по синхронизации - ничего и не может произойти и обновлять состояние не надо
    if (this.model && this.syncState && this.syncState.mercuryExchangeStatus < 2
      && (!this.syncState.mercuryExchangeNextTime || new Date(this.syncState.mercuryExchangeNextTime) > new Date())) {
      return;
    }

    if (this.updateSyncStatePending) {
      return;
    }

    this.updateSyncStatePending = true;

    this.metadataService.getSyncState(this.id, 2).subscribe({
      next: data => {
        if (isRetry && this.syncState && +this.syncState.mercuryExchangeStatus !== +data.mercuryExchangeStatus) {
          this.dataCachingService.removeCachedData('EditStableProducts', this.id.toString());
          this.refreshFormDataInternal(true);
        }
        this.updateSyncStatePending = false;
        this.updateSyncState(data);
        if (this.syncState) {
          this.syncState.withoutMercuryUuid = true;
        }
        this.dataCachingService.addToCache('EditStableProductsSyncState', this.id.toString(), this.syncState);
      }, error: () => {
        this.updateSyncStatePending = false;
      }
    });
  }

  updateSyncState(syncData: any) {
    if (syncData) {
      this.syncState = syncData;
      this.syncState.isEditStarted = false;
      if (syncData.mercuryExchangeLastResultText) {
        try {
          this.syncStateResult = JSON.parse(syncData.mercuryExchangeLastResultText);
        } catch (ignored) {
          this.syncStateResult = undefined;
        }
      }
    }
  }

  startEditSyncState(state: any) {
    if (!this.syncStateForUpdate) {
      this.buildSyncState(state);
    }

    state.isEditStarted = !state.isEditStarted;
  }

  cancelEditSyncState(state: any) {
    state.isEditStarted = false;
    this.syncStateForUpdate = undefined;
  }

  saveEditSyncState(state: any) {
    state.isEditStarted = false;
    this.metadataService.updateConversation(this.syncStateForUpdate.value)
      .subscribe(() => {
        this.syncStateForUpdate = undefined;
        this.refreshFormData();
      });
  }

  updateSelected($event: any) {
    if ($event.api) {
      $event.api.forEachNode(node => {
        if (this.model.selectedRows.some(el => el.id === node.data.id)) {
          $event.api.getRowNode(node.id).setSelected(true);
        }
      });
    }
  }
}
