import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder } from '@angular/forms';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { SecurityService } from '../../../logic/services/security.service';
import { EditAnimalEventCommonFormComponent } from './edit-animal-event-common-form.component';
import { FormHelper } from '../../../ui/controls/form-helper';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { StringHelper } from '../../../helpers/string-helper';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { combineLatest, Observable, of } from 'rxjs/index';

@Component({
  templateUrl: './create-animal-event.component.html'
})
export class CreateAnimalEventComponent implements OnInit {

  @ViewChild('animalEventFormChild')
  animalEventFormChild: EditAnimalEventCommonFormComponent;

  @Input() model: any;
  animalId: number;
  stableId: number;
  eventTypeId: number;
  creating = false;
  created = false;
  animals: any[];
  quarantineId: number;
  stables: any[];
  diseaseTypeId: number;
  selectedAnimalsCount = 0;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private cache: AddressPersonFioCacheService,
              private bookmarkService: BookmarkService,
              private animalDataService: AnimalDataService,
              private dataCachingService: DataCachingService,
              private appNavigationService: AppNavigationService,
              private securityService: SecurityService,
              private lookupSourceService: LookupSourceService,
              public cdr: ChangeDetectorRef,
              private fb: FormBuilder) {
  }

  ngOnInit() {

    this.securityService.getUserInfo().subscribe(currentUserInfo => {


      this.router.events.subscribe((val) => {
        if (!this.created) {
          this.refreshCurrentBookmark(true);
        }
      });

      this.route.paramMap
        .subscribe(params => {

          const randomUniqueTag = params.get('randomUniqueTag');
          this.animalId = params.get('animalId') ? +params.get('animalId') : undefined;
          this.stableId = params.get('stableId') ? +params.get('stableId') : undefined;
          this.eventTypeId = params.get('eventTypeId') ? +params.get('eventTypeId') : undefined;
          this.animals = params.get('animals') ?  JSON.parse(params.get('animals')) : undefined;
          this.quarantineId = params.get('quarantineId') ?  +params.get('quarantineId') : undefined;
          this.stables = params.get('stables') ?  JSON.parse(params.get('stables')) : undefined;
          this.diseaseTypeId = params.get('diseaseTypeId') ? +params.get('diseaseTypeId') : undefined;

          const existing = this.dataCachingService.getCachedData('CreateAnimalEvent', randomUniqueTag);

          if (!existing) {

            const animal$: Observable<any> = this.animalId
              ? this.cache.getAnimal(this.animalId)
              : of(undefined);

            const animals$: Observable<any> = this.animals && this.animals.length
              ? this.animalDataService.getAnimalCommonForLookup2(this.animals)
              : of(undefined);

            combineLatest([animal$, animals$])
              .subscribe(([animal, animals]) => {
                this.model = {
                  randomUniqueTag: randomUniqueTag,
                  serverSideValidationErrors: [],
                  animal: animal
                };

                this.model.form = EditAnimalEventCommonFormComponent.createFormGroup(this.fb, this.model,
                  {
                    animalId: this.animalId,
                    stableId: this.stableId,
                    eventTypeId: this.eventTypeId,
                    diseaseTypeId: this.diseaseTypeId,
                    date: StringHelper.getISODate(new Date()),
                    animals: this.animals,
                    quarantineId: this.quarantineId,
                    stables: this.stables,
                    animalEventSingleResults:
                      (animals || [])
                        .filter(el => !(el.animalGroup || false))
                        .map(el => {
                          return {
                            animalId: el.id,
                            caption: el.caption,
                          };
                        }),
                    animalEventGroupResults: (animals || [])
                      .filter(el => el.animalGroup || false)
                      .map(el => {
                        return {
                          animalId: el.id,
                          caption: el.caption,
                          animalTypeId: el.animalTypeId,
                          animalSubTypeId: el.animalSubTypeId,
                        };
                      }),
                  },
                  () => this.model.animal);

                this.model.form.get('animalGroup').setValue(this.model.animal ? this.model.animal.animalGroup : false);

                this.lookupSourceService.getLookupObj('event-type').subscribe(lookup => {
                  this.model.form.get('eventTypeId').valueChanges.subscribe(() => {
                    this.model.eventType = lookup['Obj' + this.model.form.get('eventTypeId').value];
                    this.model.form.get('eventKind').setValue(this.model.eventType ? this.model.eventType.eventKind : null);
                  });
                  this.model.eventType = lookup['Obj' + this.model.form.get('eventTypeId').value];
                });

                this.invalidateCaches();

                this.dataCachingService.addToCache('CreateAnimalEvent', randomUniqueTag, this.model, 10);

                this.refreshCurrentBookmark();
              });
          } else {
            this.model = existing;
            this.refreshCurrentBookmark();
          }
        });
    });
  }

  private invalidateCaches() {
    if (this.animalId) {
      this.dataCachingService.removeCachedData('EditAnimalEvents', this.animalId.toString());
    }
    if (this.stableId) {
      this.dataCachingService.removeCachedData('EditStableEvents', this.stableId.toString());
    }
  }

  refreshCurrentBookmark(skipIfNotExists?: boolean) {
    this.lookupSourceService.getLookupObj('event-type').subscribe(lookup => {
      const caption = lookup[this.model.form.get('eventTypeId').value] || '';

      let parentTag;

      if (this.quarantineId) {
        parentTag = '/quarantine-edit' + this.quarantineId.toString();
      } else if (this.animalId) {
        parentTag = '/animal-edit' + this.animalId.toString();
      } else if (this.stableId) {
        parentTag = '/stable-edit' + this.stableId.toString();
      }

      this.bookmarkService.addOpenCardBookmark(this.model.randomUniqueTag,
        `Новое мероприятие ${caption}`,
        ['/animal-event-create', this.model.randomUniqueTag, {animalId: this.animalId, stableId: this.stableId}],
        'color-picker', skipIfNotExists, parentTag);
    });
  }

  createCard(continueCreating?: boolean) {

    FormHelper.markAsSubmitted(this.model.form);

    if (!this.model.form.valid) {
      return;
    }

    const dataToStore = this.model.form.value;

    this.creating = true;

    if (dataToStore.animalStableMode) {
      dataToStore.animalId = dataToStore.animals[0];
    }

    if (dataToStore.drugs) {
      for (let i = 0; i < dataToStore.drugs.length; i++) {
        if (dataToStore.drugs[i].drugId) {
          dataToStore.drugs[i].drugTypeId = null;
        }
      }
    }

    const storeObs = !(dataToStore.animals || []).length && !(dataToStore.stables || []).length && !(dataToStore.diseases || []).length &&
                     !(dataToStore.animalEventSingleResults || []).length && !(dataToStore.animalEventGroupResults || []).length
      ? this.animalDataService.storeAnimalEvent(dataToStore)
      : this.animalDataService.createAnimalEvents(dataToStore);

    storeObs.subscribe((createdAnimalEvent: any) => {
      this.creating = false;
      this.created = true;

      this.bookmarkService.removeOpenCardBookmark(['/animal-event-create'], this.model.randomUniqueTag);
      if (continueCreating) {
        this.animalEventFormChild.gridApiSingle = null;
        this.animalEventFormChild.gridApiGroup = null;
        this.animalEventFormChild.gridApiStableAnimals = null;
        this.appNavigationService.performAnimalEventCreation(this.animalId, this.eventTypeId, this.stableId,
          dataToStore.animals, dataToStore.stables);
      } else {
        this.appNavigationService.performAnimalEventEditing(createdAnimalEvent.id);
      }
    }, error => {
      this.creating = false;
      FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model, this.model.form);
    });
  }

  getSelectedAnimalsCount() {
    const singleAnimals = this.model.form.get('animalEventSingleResults') as FormArray;
    const groupAnimals = this.model.form.get('animalEventGroupResults') as FormArray;
    this.selectedAnimalsCount = singleAnimals.length + groupAnimals.length;
    this.cdr.detectChanges();
  }
}
